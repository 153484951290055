import { ComponentProps, ReactNode } from 'react';

export type ID = string | number;

export type NumericId = number;

export type ISOTimestamp = string;

export type ISODate = string;

export type AccessRightType = 'view' | 'edit';

export type Environment = 'PROD' | 'DEV' | 'QA' | 'UAT';

export type TransactionType = 'Credit' | 'Debit';

export interface IObject {
  [key: string]: any;
}

export type Nullable<T> = T | null;

export type BarVisibility = 'INITIAL' | 'SHOW' | 'HIDE';

export enum DataAccessRole {
  All = 'all_workspace',
  Shared = 'shared',
}

export enum UserRoleId {
  Admin = 1,
  Creator = 2,
  Editor = 3,
  Viewer = 4,
  Guest = 5,
}

export enum UserRole {
  Admin = 'admin',
  Creator = 'creator',
  Editor = 'editor',
  Viewer = 'viewer',
  Guest = 'guest',
}

export enum SRUserTypes {
  CartoAdmin = 1,
  NormalCarto = 2,
}

export enum BetaFeature {
  OrderQueue = 'order-queue',
  StreetView = 'street-view',
  OrderRating = 'order-rating',
  Feed = 'feed',
  Attributes = 'attributes',
  AddMoreBulkOrder = 'add-more-bulk-order',
  GeoJsonUpload = 'geo-json-upload',
  GeoJsonDownload = 'geo-json-download',
  ParcelRepair = 'parcel-repair',
  ImageryRepair = 'imagery-repair',
  TabulaUpload = 'tabula-upload',
  ExportKml = 'export-kml',
  CopyViewId = 'copy-view-id',
  EnableNearmapPreview = 'enable-nearmap-preview',
  ServiceItem = 'service-item',
  Dashboards = 'show_reports',
  AddWorkspace = 'add_workspace',
  Estimations = 'estimations_v2',
}

export interface GuardProps {
  children: ReactNode;

  /** (Optional) Fallback component if condition is not satisfied */
  fallback?: ReactNode;

  /** (Optional) Extended Fallback props if condition is not satisfied. You may provide fallback OR fallbackProps at once */
  fallbackProps?: ComponentProps<any>;

  /** (Optional) Or condition which should be match with main condition to determine if component should render or not  */
  or?: boolean;
}

export enum AccessRight {
  View = 'view',
  Edit = 'edit',
}

export enum OrderStatus {
  Draft = 1,
  MeasurementOngoing = 2,
  AwaitingApproval = 3,
  Approved = 4,
  ResolvingFeedback = 5,
  Archived = 6,
  SelfMeasured = 7,
  BoltFailed = 8,
  BoltPreview = 9,
  BoltApproved = 10,
  BoltInVerification = 11,

  /** Confirmed Isn't A Status Code In DB - This Is Just For Frontend */
  Confirmed = 12,
}

export enum PlasmicOrderStatus {
  Draft = 'draft',
  MeasurementOngoing = 'measurement_ongoing',
  AwaitingApproval = 'awaiting_approval',
  Approved = 'approved',
  ResolvingFeedback = 'resolving_feedback',
  SelfMeasured = 'self_measured',
  BoltFailed = 'bolt_failed',
  BoltPreview = 'bolt_preview',
  BoltApproved = 'bolt_approved',
  BoltInVerification = 'bolt_in_verification',
  Confirmed = 'confirmed',
}

export enum OrderStatusName {
  Draft = 'Draft',
  MeasurementOngoing = 'Measurement Ongoing',
  AwaitingApproval = 'Awaiting Approval',
  Approved = 'Approved',
  ResolvingFeedback = 'Resolving Feedback',
  SelfMeasured = 'Self Measured',
  BoltFailed = 'Bolt Failed',
  BoltPreview = 'Bolt Preview',
  BoltApproved = 'Bolt Approved',
  BoltInVerification = 'Bolt In Verification',
  Archived = 'Archived',
}

export enum HttpStatus {
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  RequestTimeout = 408,
  UnprocessableEntity = 422,
  TooManyRequests = 429,
  InternalServerError = 500,
  BadGateway = 502,
}

export enum SiteReconError {
  Unauthorized = 'SR-BIZ-ERR-009',
  SubscriptionRequired = 'SR-BIZ-ERR-034',
  PropertyAccessLocked = 'SR-BIZ-ERR-055',
  InsufficientTakeOffCredits = 'SR-BIZ-ERR-010',
  EnterpriseSeatsExhausted = 'SR-BIZ-ERR-053',
  PaymentRequired = 'SR-BIZ-ERR-054',
  UserInviteConflict = 'SR-BIZ-ERR-056',
  InsufficientNearmapCredits = 'SR-PLU-BIZ-ERR-015',
  NearmapLimitExhausted = 'SR-BIZ-ERR-EMP-014',
  CannotPlaceOrderWithoutFeature = 'SR-BIZ-ERR-EMP-006',
  InsufficientCreditsEmp = 'SR-BIZ-ERR-EMP-007',
}

export enum ViewType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  ESTIMATION = 'estimation',
}

export enum ModifySyncState {
  UNSYNCED = 'unsynced',
  SYNCING = 'syncing',
  SYNCED = 'synced',
}
