import { ORDER_ACTIONS } from './action_types';
import { _get, _uniqBy } from '../../helpers/utilities/lodashUtils';

const INITIAL_STATE = {
  orderId: 0,
  hash: '',
  expectedCost: '',
  expectedTime: '',
  imageryCost: '',
  loading: false,
  parcel: {},
  layers: [],
  layerRefs: {},
  area: 0,
  nextHashHome: '',
  nearMapDates: {},
  orderData: null,
  nextAdd: '',
  nextCust: '',
  nextStatus: '',
  reduxView: [],
  isPageLoaded: false,
  featureListInfo: [],
  loaderProg: {},
  currentViewData: null,
  editingToolVal: false,
  orderStatus: null,
  orderOrganisation: null,
  parcelStyle: {},
  isSharedOrder: false,
  mapRotateData: false,
  topologyWarning: {},
  selectDeselectFeature: [],
  tags: [],
  allAvailableTags: [],
  suggestedTags: [],
  reload: false,
  orderIdForTag: 0,
  orderViews: [],
  listOfPeopleOfOrganizations: [],
  orderSharedWith: [],
  orderStatusForPeople: 0,
  bulkOrderTagModal: false,
  uploadLayerOnMapModal: false,
  userAccessRight: null,
  creatorId: null,
  layerList: [],
  attributePanelVisible: false,
  visibleAttributesInLayerPanel: [],
  selectedNearmap: null,
  modifyParcelStatus: null,
  isDeletingParcel: false,
  isDraftNearmapLimitExhausted: false,
  draftNearmapRequiredLimit: null,
  propertyReportId: -1,
  estimationAssignedItemBarComponent: null,
  estimationViewCheckedItems: [],
};

const data = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ORDER_ACTIONS.SET_AREA:
      return {
        ...state,
        area: payload,
      };

    case ORDER_ACTIONS.CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_ACTIONS.SET_LAYER_REQUEST:
      return {
        ...state,
        layers: payload,
      };
    case ORDER_ACTIONS.UPDATE_LAYER_REFS:
      return {
        ...state,
        layerRefs: payload,
      };
    case ORDER_ACTIONS.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        hash: payload.hashValue,
        orderId: payload.orderId,
        parcel: payload.parcelJson
          ? {
              ...payload.parcelJson,
            }
          : {
              ...state.parcel,
            },
        nextHashHome: payload.nextHash.nextHash,
        nextAdd: payload.nextHash.nextAddress,
        nextStatus: payload.nextHash.status,
        nextCust: payload.nextHash.customerName,
      };

    case ORDER_ACTIONS.CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ORDER_ACTIONS.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_ACTIONS.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        parcel: payload.parcelJson
          ? {
              ...payload.parcelJson,
            }
          : {
              ...state.parcel,
            },
        expectedCost: payload.estimatedCost ?? state.expectedCost,
        expectedTime: payload.estimatedTime ?? state.expectedTime,
        imageryCost: payload.imageryCost ?? state.imageryCost,
      };

    case ORDER_ACTIONS.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ORDER_ACTIONS.SET_PARCEL:
      return {
        ...state,
        parcel: payload,
      };

    case ORDER_ACTIONS.SET_PARCEL_STYLE:
      return {
        ...state,
        parcelStyle: payload,
      };

    case ORDER_ACTIONS.RESET_NEXTHASH_HOME:
      return {
        ...state,
        nextHashHome: '',
        nextAdd: '',
        nextCust: '',
        nextStatus: '',
      };

    case ORDER_ACTIONS.UPDATE_NEAR_MAP_DATES:
      return {
        ...state,
        nearMapDates: payload,
      };
    case ORDER_ACTIONS.GET_NEARMAP_INFO:
      return {
        ...state,
        loading: false,
        nearMapDates: payload.nearMapDates !== null && payload.nearMapDates,
      };
    case ORDER_ACTIONS.GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ORDER_ACTIONS.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderData: payload,
        fixedOrderData: payload,
      };

    case ORDER_ACTIONS.UPDATE_ORDER_TAGS:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          tags: payload,
        },
      };

    case ORDER_ACTIONS.UPDATE_PROPERTY_NAME:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          propertyName: payload,
        },
      };

    case ORDER_ACTIONS.UPDATE_ORDER_DETAILS:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          propertyName: payload.propertyName,
          customerName: payload.customerName,
          customerId: payload.customerId,
          description: payload.description,
        },
      };

    case ORDER_ACTIONS.UPDATE_ORDER_IMAGERY_DATE:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          imagerydate: payload,
        },
      };

    case ORDER_ACTIONS.GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ORDER_ACTIONS.UPDATE_MAP_TOOLS:
      return {
        ...state,
        loading: false,
      };
    case ORDER_ACTIONS.RED_VIEWS:
      return {
        ...state,
        reduxView: payload,
      };
    case ORDER_ACTIONS.RESET_ORDER_DATA:
      return {
        ...state,
        orderData: null,
      };
    case ORDER_ACTIONS.IS_PAGE_LOADED:
      return {
        ...state,
        isPageLoaded: payload,
      };
    case ORDER_ACTIONS.TOGGLE_SNAPSHOT_V1_FLOW:
      return {
        ...state,
        snapshotV1Flow: payload,
      };
    case ORDER_ACTIONS.TOGGLE_SNAPSHOT_V2_FLOW:
      return {
        ...state,
        snapshotV2Flow: payload,
      };
    case ORDER_ACTIONS.TOGGLE_PROPERTY_REPORT:
      return {
        ...state,
        propertyReportId: payload,
      };
    case ORDER_ACTIONS.FEATURE_LIST:
      return {
        ...state,
        featureListInfo: payload,
      };
    case ORDER_ACTIONS.ORDER_BAR:
      return {
        ...state,
        loaderProg: payload,
      };
    case ORDER_ACTIONS.CURRENT_VIEW:
      return {
        ...state,
        currentViewData: payload,
      };
    case ORDER_ACTIONS.EDIT_REDUX:
      return {
        ...state,
        editingToolVal: payload,
      };
    case ORDER_ACTIONS.SET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: payload,
      };

    case ORDER_ACTIONS.SET_ORDER_VIEWS:
      return {
        ...state,
        orderViews: payload || [],
      };
    case ORDER_ACTIONS.SET_ORDER_ORGANISATION:
      return {
        ...state,
        orderOrganisation: payload,
      };
    case ORDER_ACTIONS.SET_ORDER_FEATURES:
      return {
        ...state,
        orderFeatures: {
          ...state.orderFeatures,
          ...payload,
        },
      };
    case ORDER_ACTIONS.BASE_VIEW:
      return {
        ...state,
        ...payload,
      };
    case ORDER_ACTIONS.LAYER_COMPONENTS:
      return {
        ...state,
        layerComponents: payload,
      };

    case ORDER_ACTIONS.LAYERS_DATA:
      return {
        ...state,
        layerList: payload,
      };
    case ORDER_ACTIONS.SHARED_ORDER:
      return {
        ...state,
        isSharedOrder: payload,
      };

    case ORDER_ACTIONS.ADD_TAGS:
      return {
        ...state,
        tags: payload,
      };

    case ORDER_ACTIONS.ADD_AVAILABLE_TAGS:
      return {
        ...state,
        allAvailableTags: _uniqBy(payload),
      };

    case ORDER_ACTIONS.RELOAD_ORDERS:
      return {
        ...state,
        reload: !state.reload,
        tags: [],
      };

    case ORDER_ACTIONS.ADD_ORDER_ID_FOR_TAG:
      return {
        ...state,
        orderIdForTag: payload,
      };

    case ORDER_ACTIONS.ADD_SUGGESTED_TAGS:
      return {
        ...state,
        suggestedTags: payload,
      };

    case ORDER_ACTIONS.PROVIDE_PEOPLE_DATA_TO_MODAL:
      return {
        ...state,
        orderViews:
          Array.isArray(payload?.views) && payload?.views?.length > 0
            ? payload?.views
            : [],
        orderSharedWith:
          Array.isArray(payload?.orderSharedWith) &&
          payload?.orderSharedWith?.length > 0
            ? payload?.orderSharedWith
            : [],
        orderStatusForPeople: payload?.orderStatus ?? 0,
      };

    case ORDER_ACTIONS.ADD_LIST_OF_PEOPLE:
      return {
        ...state,
        listOfPeopleOfOrganizations: payload,
      };

    case ORDER_ACTIONS.EMPTY_TAG_ARRAY:
      return {
        ...state,
        tags: [],
      };

    case ORDER_ACTIONS.ROTATE_MAP:
      return {
        ...state,
        mapRotateData: payload,
      };
    case ORDER_ACTIONS.IS_BULK_TAG:
      return {
        ...state,
        bulkOrderTagModal: payload,
      };

    case ORDER_ACTIONS.UPDATE_TOPOLOGY_WARNING:
      let dataToBeStored = { ...payload };
      let overlap = {};
      _get(dataToBeStored, 'overlap', []).forEach(function (item) {
        overlap[item.primaryGeometry] = item.overlapGeometry;
      });
      let kink = {};
      _get(dataToBeStored, 'kink', []).forEach(function (item) {
        kink[item] = true;
      });
      let nullGeometry = {};
      _get(dataToBeStored, 'nullGeometry', []).forEach(function (item) {
        nullGeometry[item] = true;
      });
      let duplicate = {};
      _get(dataToBeStored, 'duplicate', []).forEach(function (item) {
        duplicate[item] = true;
      });
      return {
        ...state,
        topologyWarning: {
          ...state.topologyWarning,
          [payload.layerId]: {
            ...dataToBeStored,
            overlap,
            kink,
            duplicate,
            nullGeometry,
          },
        },
      };
    case ORDER_ACTIONS.REMOVE_COMPONENT_TOPOLOGY_WARNING:
      let newOverlap = state.topologyWarning[payload.layerId].overlap;
      delete newOverlap[payload.componentId];
      let newKink = state.topologyWarning[payload.layerId].kink;
      delete newKink[payload.componentId];
      let nweNullGeometry = state.topologyWarning[payload.layerId].nullGeometry;
      delete nweNullGeometry[payload.componentId];
      let newDuplicate = state.topologyWarning[payload.layerId].duplicate;
      delete newDuplicate[payload.componentId];
      return {
        ...state,
        topologyWarning: {
          ...state.topologyWarning,
          [payload.layerId]: {
            ...state.topologyWarning[payload.layerId],
            overlap: newOverlap,
            kink: newKink,
            duplicate: newDuplicate,
            nullGeometry: nweNullGeometry,
          },
        },
      };
    case ORDER_ACTIONS.SELECT_DESELECT_FEATURE:
      return {
        ...state,
        selectDeselectFeature: payload,
      };
    case ORDER_ACTIONS.IS_EDITABLE_VIEW:
      return {
        ...state,
        ...payload,
      };
    case ORDER_ACTIONS.UPLOAD_LAYER_ON_MAP_MODAL_OPEN:
      return {
        ...state,
        uploadLayerOnMapModal: true,
      };
    case ORDER_ACTIONS.UPLOAD_LAYER_ON_MAP_MODAL_CLOSE:
      return {
        ...state,
        uploadLayerOnMapModal: false,
      };
    case ORDER_ACTIONS.USER_ACCESS_RIGHT:
      return {
        ...state,
        userAccessRight: payload,
      };
    case ORDER_ACTIONS.CREATOR_ID:
      return {
        ...state,
        creatorId: payload,
      };
    case ORDER_ACTIONS.SET_ATTRIBUTE_PANEL_VISIBILITY:
      return {
        ...state,
        attributePanelVisible: payload,
      };
    case ORDER_ACTIONS.SET_VISIBLE_ATTRIBUTES_IN_LAYER_PANEL:
      return {
        ...state,
        visibleAttributesInLayerPanel: payload,
      };
    case ORDER_ACTIONS.SET_SELECTED_NEARMAP:
      return {
        ...state,
        selectedNearmap: payload,
      };
    case ORDER_ACTIONS.SET_MODIFY_PARCEL_STATUS:
      return {
        ...state,
        modifyParcelStatus: payload,
      };
    case ORDER_ACTIONS.SET_IS_DELETING_PARCEL:
      return {
        ...state,
        isDeletingParcel: payload,
      };
    case ORDER_ACTIONS.SET_IS_DRAFT_NEARMAP_LIMIT_EXHAUSTED:
      return {
        ...state,
        isDraftNearmapLimitExhausted: payload,
      };
    case ORDER_ACTIONS.SET_DRAFT_NEARMAP_REQUIRED_LIMIT:
      return {
        ...state,
        draftNearmapRequiredLimit: payload,
      };
    case ORDER_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ORDER_ACTIONS.SET_EM_ASSIGNED_ITEM_BAR_COMPONENT:
      return {
        ...state,
        estimationAssignedItemBarComponent: payload,
      };
    case ORDER_ACTIONS.SET_EM_VIEW_CHECKED_ITEMS:
      return {
        ...state,
        estimationViewCheckedItems: payload,
      };
    default:
      return state;
  }
};

export default data;
