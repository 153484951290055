import { FeatureType } from '../../../components/feature-attributes/types';
import { ID, Nullable, NumericId } from '../../common/types';
import { ViewType } from '@/modules/common/types';
import { ServiceItemColorData } from '../types/service-item.types';

// Address confirmation flow
export interface ParcelJson {
  coordinates: Coordinates;
  type: string;
}

export interface AddressDetails {
  address: string;
  propertyName: string;
  latitude: string;
  longitude: string;
  pincode: string;
  parcelArea: string;
  parcelJson: ParcelJson;
  parcel_style: {
    style: string;
  };
  city: string;
  state: string;
  features: string[];
  feature_ids: number[];
  uploadedfiles: string;
  uploadedfilesbuffer: string;
  ordernote: string;
  imagerydate: string;
  noOfFeatures: number;
  customerName: string;
  customerId: string;
  description: string;
  placeId: string;
  bulk_order: any;
  estimatedCost: number;
  orderNo: number;
  id: number;
  created_at: string;
  updated_at: string;
  access_rights: string;
  boltQcEstimatedTime: number;
  estimatedDeliveryTime: Nullable<string>;
  estimatedTime: number;
  currentTime: string;
  isParcelVerified: boolean;
  imageryCost: number;
  uniqueOrder: string;
  hashNumber: string;
  prevDetail: any;
  tags: any[];
  nextDetail: any;
  hoursBlockedBeforeAvailability: number;
  noOfOrdersInQueue: number;
  queueName: string;
}

export interface TileDataItem {
  id: number;
  imgUrl: string;
  minLat: string;
  minLong: string;
  maxLat: string;
  maxLong: string;
  is_tiledata: number;
  created_at: string;
  updated_at: string;
  orderNo: number;
}

export interface OrderProductItem {
  orderId: number;
  status: number;
  orderHashed: string;
  default_type: string;
  updated_at: string;
  created_by: number;
  isSubscriptionRestricted: boolean;
  remainingTime: string;
  organizationId: number;
}

export interface ViewListItem {
  viewId: number;
  isBaseView: boolean;
  isEditable: boolean;
  isDefault: boolean;
  orderId: number;
  name: string;
  viewType: ViewType;
  linkedView: Nullable<number>;
  lastFetchedTime: string;
}

export interface QueueItem {
  queueName: string;
  orders: Order[];
}

export type Coordinates = number[][][];

// Parcel confirmation
export interface Order {
  orderId: number;
  status: string;
  orderHashed: string;
  address: string;
  city: string;
  state: string;
  createdBy: number;
  createdAt: string;
  estimatedDeliveryTime: string;
  estimatedStartTime: string;
}

// Order Card - Select feature & get measurement
export interface Feature {
  featureId: number;
  name: string;
  type: string;
  category: string;
  shortDesc: string;
  longDesc: string;
  imageUrl: string;
  status: string;
  canBeOrdered: boolean;
  isDefaultOrderable: boolean;
  style: Style;
  maintainer: string;
  featureRate: number;
  attributes: any[];
  createdAt: string;
  createdBy: string;
}

export interface FeatureListSummary {
  count: number;
  paginated: boolean;
}

export interface SingleComponent {
  name: string;
  componentId: number;
  componentName?: string;
  layerId: NumericId;
  featureId?: NumericId;
  featureType?: FeatureType;
  layerIndex: number;
  geoJson: string;
  status: string;
  style: Style;
  warningStatus: string;
  attributes: ComponentAttribute[];
  area: number;
  length: Nullable<number>;
  perimeter: Nullable<number>;
  serviceItemIds: NumericId[];
  unit?: string;
}

export interface Layer {
  name: string;
  layerId: ID;
  viewId: number;
  featureId: number;
  featureType: FeatureType;
  layerIndex: number;
  style: Style;
  noOfComponents: number;
  isEditable: boolean;
  status: string;
  warningStatus: string;
  attributeIds: number[];
  serviceItemIds: NumericId[];
}

export interface LayerWithComponents extends Layer {
  components: SingleComponent[];
}

export interface TopologyWarning {
  count: Nullable<number>;
  kink: any[];
  overlap: any[];
  duplicate: any[];
  nullGeometry: any[];
}

export interface User {
  id: number;
  fn: string;
  em: string;
  user_role: string;
  role_id: number;
  last_login: string;
  profile_pic: Nullable<string>;
  data_access_role: string;
  order_creation_role_id: number;
  is_user_deactivated: boolean;
}

export interface ComponentAttribute {
  attributeId: number;
  componentId: number;
  multiselect: string[];
}

export interface Tag {
  id: number;
  name: string;
  maintainer: string;
}

export interface ReviewTag {
  id: number;
  name: string;
  color: string;
  noteCount: number;
}

export interface Style {
  color: string;
  stroke: number;
  opacity: number;
  fillColor: string;
  arrowSize: number;
  arrowType: string;
  arrowColor: string;
  symbolSize: number;
  symbolType: string;
  symbolColor: string;
}

export interface Tile {
  id: string;
  scale: number;
  type: string;
}

export interface Location {
  country: string;
  region: string;
  state: string;
}

export interface Resource {
  tiles: Tile[];
}

export interface Survey {
  location: Location;
  resources: Resource;
  tags: Record<string, any>;
  captureDate: string;
  captureDateTime: string;
  firstPhotoTime: string;
  id: string;
  lastPhotoTime: string;
  onlineTime: string;
  pixelSize: number;
  timezone: string;
  utcOffset: number;
}

export interface Pricing {
  limitStart: number;
  limitEnd: number;
  costPerAcre: number;
}

export interface ReviewTagData {
  default_tag: ReviewTag[];
  org_tag: ReviewTag[];
}

export enum EstimationLayerType {
  TAKEOFF = 'takeoff',
  UNASSIGNED = 'unassigned',
  SERVICE_ITEM = 'serviceItem',
}

/** Types added for ServiceItems **/
export interface LayerWithComponents extends Layer {
  components: SingleComponent[];
  type: EstimationLayerType;
}

export interface ApiServiceItem {
  service_item_name: string;
  service_item_id: NumericId;
  service_id: NumericId;
  service_name: string;
  feature_ids: NumericId[];
  service_item_color: string;
  is_default: boolean;
  is_archived: boolean;
}

export interface ServiceItem {
  name: string;
  id: NumericId;
  color: string;
  serviceId: NumericId;
  serviceName: string;
  featureIds: NumericId[];
  isArchived?: boolean;
  isDefault?: boolean;
}

export interface Service {
  serviceId: NumericId;
  serviceName: string;
  serviceItems: Omit<ServiceItem, 'serviceId' | 'serviceName'>[];
}

export interface LayerPanelServiceDataItem extends ServiceItem {
  type: EstimationLayerType.SERVICE_ITEM;
  components: SingleComponent[];
}

export type LayerPanelDataItem =
  | LayerPanelServiceDataItem
  | LayerWithComponents;

export type LayerPanelData = LayerPanelDataItem[];

export interface ActionCenterServiceItemDisabled {
  isDisabled: boolean;
  message?: string;
}

export interface ActionCenterServiceItem {
  id: NumericId;
  featureIds: NumericId[];
  name: string;
  assigned: number;
  isDefault: boolean;
  isArchived: boolean;
  disabled: ActionCenterServiceItemDisabled | null;
}

export interface ActionCenterService {
  serviceId: NumericId;
  serviceName: string;
  assigned: number;
  serviceItems: ActionCenterServiceItem[];
}

export interface ActionCenterServiceWithComponentId
  extends Omit<ActionCenterService, 'assigned'> {
  assigned: Set<NumericId>;
}

export interface ActionCenterData {
  totalSelected: number;
  selectedLayer: string;
  services: ActionCenterService[];
}

export interface ServiceItemChange {
  id: NumericId;
  checked: boolean;
}

export type ServiceItemAssignment = NumericId[];

export interface ActionCenterServiceItemMutation
  extends ActionCenterServiceItem {
  checked: boolean;
}

export interface ActionCenterServiceMutation
  extends Omit<ActionCenterService, 'serviceItems'> {
  serviceItems: ActionCenterServiceItemMutation[];
}

export interface SelectedComponent {
  id: NumericId;
  name: string;
  selectedComponents: NumericId[];
}

export interface ActionCenterMutationData {
  totalSelected: number;
  services: ActionCenterServiceMutation[];
}

export interface ServiceItemAssignmentComponent {
  component: SingleComponent;
  serviceItems: ServiceItemColorData[];
}

export interface ServiceItemWithMeasurement {
  service_item_name: string;
  service_item_id: NumericId;
  linked_features: { feature_id: number | undefined; measurement: number }[];
  measurement: number;
  unit: string;
}

export interface FeatureWithMeasurement {
  feature_id: number;
  feature_name: string;
  measurement: number;
  unit: string;
}
