import { ORDER_ACTIONS } from './action_types';

export const createOrderRequest = () => ({
  type: ORDER_ACTIONS.CREATE_ORDER_REQUEST,
});

export const createOrderSuccess = (data) => ({
  type: ORDER_ACTIONS.CREATE_ORDER_SUCCESS,
  payload: data,
});

export const createOrderFailure = (data) => ({
  type: ORDER_ACTIONS.CREATE_ORDER_FAILURE,
  payload: data,
});

export const updateOrderRequest = () => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_REQUEST,
});

export const updateOrderSuccess = (data) => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_SUCCESS,
  payload: data,
});

export const updateOrderFailure = (data) => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_FAILURE,
  payload: data,
});

export const setParcel = (data) => ({
  type: ORDER_ACTIONS.SET_PARCEL,
  payload: data,
});

export const setParcelStyle = (data) => ({
  type: ORDER_ACTIONS.SET_PARCEL_STYLE,
  payload: data,
});

export const setArea = (data) => ({
  type: ORDER_ACTIONS.SET_AREA,
  payload: data,
});

export const resetNextHashHome = () => ({
  type: ORDER_ACTIONS.RESET_NEXTHASH_HOME,
});

export const updateNearMapDates = (data) => ({
  type: ORDER_ACTIONS.UPDATE_NEAR_MAP_DATES,
  payload: data,
});

export const getNearMapInfo = (data) => ({
  type: ORDER_ACTIONS.GET_NEARMAP_INFO,
  payload: data,
});
export const getOrderRequest = () => ({
  type: ORDER_ACTIONS.GET_ORDER_DETAIL_REQUEST,
});

export const getOrderSuccess = (data) => ({
  type: ORDER_ACTIONS.GET_ORDER_DETAIL_SUCCESS,
  payload: data,
});

export const updateOrderTags = (tags) => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_TAGS,
  payload: tags,
});

export const updatePropertyName = (data) => ({
  type: ORDER_ACTIONS.UPDATE_PROPERTY_NAME,
  payload: data,
});

export const updateImageryDate = (data) => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_IMAGERY_DATE,
  payload: data,
});

export const updateOrderDetails = (data) => ({
  type: ORDER_ACTIONS.UPDATE_ORDER_DETAILS,
  payload: data,
});

export const getOrderFailure = (data) => ({
  type: ORDER_ACTIONS.GET_ORDER_DETAIL_FAILURE,
  payload: data,
});

export const getSampleOrder = (data) => ({
  type: ORDER_ACTIONS.GET_SAMPLE_ORDERS,
  payload: data,
});

export const updateMapTools = (data) => ({
  type: ORDER_ACTIONS.UPDATE_MAP_TOOLS,
  payload: data,
});

export const redViews = (data) => ({
  type: ORDER_ACTIONS.RED_VIEWS,
  payload: data,
});

export const resetOrderData = () => (dispatch) => {
  dispatch({
    type: ORDER_ACTIONS.RESET_ORDER_DATA,
  });
};
export const isPageLoaded = (flag) => ({
  type: ORDER_ACTIONS.IS_PAGE_LOADED,
  payload: flag,
});

export const storeSnap = (data) => ({
  type: ORDER_ACTIONS.STORE_SNAP,
  payload: data,
});
export const snapLoader = (data) => ({
  type: ORDER_ACTIONS.SNAP_LOADER,
  payload: data,
});

export const featureList = (data) => ({
  type: ORDER_ACTIONS.FEATURE_LIST,
  payload: data,
});

export const loaderBar = (data) => ({
  type: ORDER_ACTIONS.ORDER_BAR,
  payload: data,
});

export const showMeLaterAction = (data) => ({
  type: ORDER_ACTIONS.SHOW_ME_LATER,
  payload: data,
});

export const currentView = (data) => ({
  type: ORDER_ACTIONS.CURRENT_VIEW,
  payload: data,
});

export const setEditingRedux = (data) => ({
  type: ORDER_ACTIONS.EDIT_REDUX,
  payload: data,
});

export const setOrderStatus = (data) => ({
  type: ORDER_ACTIONS.SET_ORDER_STATUS,
  payload: data,
});

export const setOrderViews = (data) => ({
  type: ORDER_ACTIONS.SET_ORDER_VIEWS,
  payload: data,
});

export const setOrderOrganisation = (data) => ({
  type: ORDER_ACTIONS.SET_ORDER_ORGANISATION,
  payload: data,
});

export const setOrderFeatures = (data) => ({
  type: ORDER_ACTIONS.SET_ORDER_FEATURES,
  payload: data,
});

export const baseView = (data) => ({
  type: ORDER_ACTIONS.BASE_VIEW,
  payload: data,
});
export const getLayerDetails = (data) => ({
  type: ORDER_ACTIONS.SET_LAYER_REQUEST,
  payload: data,
});

export const setOrderLayerComponents = (data) => ({
  type: ORDER_ACTIONS.LAYER_COMPONENTS,
  payload: data,
});

export const setOrderLayers = (data) => ({
  type: ORDER_ACTIONS.LAYERS_DATA,
  payload: data,
});

export const updateLayerRefs = (data) => ({
  type: ORDER_ACTIONS.UPDATE_LAYER_REFS,
  payload: data,
});

export const setSharedOrder = (data) => ({
  type: ORDER_ACTIONS.SHARED_ORDER,
  payload: data,
});

export const addTagsForAOrder = (tags) => {
  return {
    type: ORDER_ACTIONS.ADD_TAGS,
    payload: [...tags],
  };
};

export const addAllTags = (allTags) => {
  return {
    type: ORDER_ACTIONS.ADD_AVAILABLE_TAGS,
    payload: [...allTags],
  };
};

export const reloadOrders = () => {
  return {
    type: ORDER_ACTIONS.RELOAD_ORDERS,
  };
};

export const addOrderIdForTag = (orderId) => {
  return {
    type: ORDER_ACTIONS.ADD_ORDER_ID_FOR_TAG,
    payload: orderId ?? 0,
  };
};

export const addSuggestedTags = (tagsArray) => {
  return {
    type: ORDER_ACTIONS.ADD_SUGGESTED_TAGS,
    payload: [...tagsArray],
  };
};

export const provideOrderInfoForAddPeople = (peopleData) => {
  return {
    type: ORDER_ACTIONS.PROVIDE_PEOPLE_DATA_TO_MODAL,
    payload: peopleData,
  };
};

export const addListOfPeople = (peopleArray) => {
  return {
    type: ORDER_ACTIONS.ADD_LIST_OF_PEOPLE,
    payload:
      Array.isArray(peopleArray) && peopleArray?.length > 0 ? peopleArray : [],
  };
};

export const emptyTag = () => {
  return {
    type: ORDER_ACTIONS.EMPTY_TAG_ARRAY,
  };
};

export const rotateMapValue = (data) => ({
  type: ORDER_ACTIONS.ROTATE_MAP,
  payload: data,
});

export const updateOrdersTopologyWarnings = (data) => ({
  type: ORDER_ACTIONS.UPDATE_TOPOLOGY_WARNING,
  payload: data,
});

export const removeComponentTopologyWarnings = (data) => ({
  type: ORDER_ACTIONS.REMOVE_COMPONENT_TOPOLOGY_WARNING,
  payload: data,
});

export const selectDeselectFeatures = (data) => ({
  type: ORDER_ACTIONS.SELECT_DESELECT_FEATURE,
  payload: data,
});

export const isBulkOrderTagModal = (isBulkTag) => ({
  type: ORDER_ACTIONS.IS_BULK_TAG,
  payload: isBulkTag,
});
export const editableView = (data) => ({
  type: ORDER_ACTIONS.IS_EDITABLE_VIEW,
  payload: data,
});

export const uploadLayerOnMapModalOpen = (data) => ({
  type: ORDER_ACTIONS.UPLOAD_LAYER_ON_MAP_MODAL_OPEN,
});

export const uploadLayerOnMapModalClose = (data) => ({
  type: ORDER_ACTIONS.UPLOAD_LAYER_ON_MAP_MODAL_CLOSE,
});

export const setUserAccessRight = (data) => ({
  type: ORDER_ACTIONS.USER_ACCESS_RIGHT,
  payload: data,
});

export const setCreatorId = (data) => ({
  type: ORDER_ACTIONS.CREATOR_ID,
  payload: data,
});

export const setAttributePanelVisibility = (payload) => ({
  type: ORDER_ACTIONS.SET_ATTRIBUTE_PANEL_VISIBILITY,
  payload,
});

export const setVisibleAttributesInLayerPanel = (payload) => ({
  type: ORDER_ACTIONS.SET_VISIBLE_ATTRIBUTES_IN_LAYER_PANEL,
  payload,
});

export const setSelectedNearmap = (payload) => ({
  type: ORDER_ACTIONS.SET_SELECTED_NEARMAP,
  payload,
});

export const setParcelModifyStatus = (payload) => ({
  type: ORDER_ACTIONS.SET_MODIFY_PARCEL_STATUS,
  payload,
});

export const setIsDeletingParcel = (payload) => ({
  type: ORDER_ACTIONS.SET_IS_DELETING_PARCEL,
  payload,
});

export const setIsDraftNearmapLimitExhausted = (payload) => ({
  type: ORDER_ACTIONS.SET_IS_DRAFT_NEARMAP_LIMIT_EXHAUSTED,
  payload,
});

export const setDraftNearmapRequiredLimit = (payload) => ({
  type: ORDER_ACTIONS.SET_DRAFT_NEARMAP_REQUIRED_LIMIT,
  payload,
});

export const toggleSnapshotV1Flow = (payload) => ({
  type: ORDER_ACTIONS.TOGGLE_SNAPSHOT_V1_FLOW,
  payload,
});

export const toggleSnapshotV2Flow = (payload) => ({
  type: ORDER_ACTIONS.TOGGLE_SNAPSHOT_V2_FLOW,
  payload,
});

export const togglePropertyReport = (payload) => ({
  type: ORDER_ACTIONS.TOGGLE_PROPERTY_REPORT,
  payload,
});

export const setLoading = (data) => ({
  type: ORDER_ACTIONS.SET_LOADING,
  payload: data,
});

export const setEstimationAssignedItemBarComponent = (data) => ({
  type: ORDER_ACTIONS.SET_EM_ASSIGNED_ITEM_BAR_COMPONENT,
  payload: data,
});

export const setEstimationViewCheckedItems = (data) => ({
  type: ORDER_ACTIONS.SET_EM_VIEW_CHECKED_ITEMS,
  payload: data,
});
