import { NotifyError } from '@/helpers/notification-utils';
import { List, Tooltip, Typography } from 'antd';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  clickLayerHighlight,
  removeSelectInteraction,
  toggleHoverOverlayPopoverInteraction,
  translateFeatureWithCpy,
} from '../../../helpers/mapUtils/featuresUtils';
import {
  cutInteractionActive,
  removeCutInteraction,
} from '../../../helpers/mapUtils/tools/cutTool';
import {
  doughnutInteractionActive,
  removeDoughnutInteraction,
} from '../../../helpers/mapUtils/tools/doughnutTool';
import {
  drawInteractionActive,
  removeDrawInteraction,
} from '../../../helpers/mapUtils/tools/drawTool';
import {
  modifyInteractionActive,
  removeModifyInteraction,
} from '../../../helpers/mapUtils/tools/modifyTool';
import {
  multiSelectTool,
  removeDragBoxInteraction,
} from '../../../helpers/mapUtils/tools/multiSelecteTool';
import {
  removeSnipInteraction,
  snipInteractionActive,
} from '../../../helpers/mapUtils/tools/snipTool';
import {
  _debounce,
  _filter,
  _get,
} from '../../../helpers/utilities/lodashUtils';
import { setEditingRedux } from '../../../store/order/actions';
import { submitMapToolThunk } from '../../../store/order/thunks';
import {
  setActiveTool,
  setMapClassnames,
  toogleMeasureTool,
} from '../../../store/user/actions';
import './style.less';
import LineTool from './stories/assets/tools/LineTool';
import PathTool from './stories/assets/tools/PathTool';
import CutTool from './stories/assets/tools/CutTool';
import LassoSplit from './stories/assets/tools/LassoSplit';
import LineSplit from './stories/assets/tools/LineSplit';
import ProFeature from './stories/assets/common/ProFeature';

import moment from 'moment';
import { removeMeasureInteraction } from '../../../helpers/mapUtils/tools/measurementTool';
import { trackEvents } from '../../../helpers/utilities';
import {
  setAttributeMultiPopupData,
  setAttributePopupData,
} from '../../../store/attributeFeature/actions';

import { unByKey } from 'ol/Observable';
import {
  BULK_INTERACTION,
  DRAW_INTERACTION,
  MODIFY_INTERACTION,
} from '../../../helpers/constants/mapTools';
import {
  lineSliceInteractionActive,
  removeLineSliceInteraction,
} from '../../../helpers/mapUtils/tools/lineSliceTool';
import { resetCrossLayerSnap } from '../../../helpers/mapUtils/tools/snapping';
import {
  drawInteractionAddPointToTrackHistory,
  drawInteractionClearPointLevelHistory,
} from '../../../store/map/actions';
import { WithBetaRelease } from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@/modules/common/types';
import { SubscriptionChip } from '@/modules/subscription/components/Chips';
import { WithMapToolGuard } from '@/modules/subscription/guards/project';
import { FeatureType } from '@/components/feature-attributes/types';
import withSubscription from '../../../modules/project/guards/SubscriptionGuard/withSubscription';
import { SubscriptionPlan } from '@/components/subscription/helpers/enum';
import MapToolbar from './stories/Toolbar/MapToolBar/MapToolBar';
import {
  getPanelWidth,
  sumOfPx,
  sumOfPxWithNumber,
} from '../../pages/project/projectComponents/helpers';

import { topologyWarningStringsArray } from '../../../helpers/mapUtils/layerUtils';
import { displayComponentName } from '../../../helpers/mapUtils/featuresUtils';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';
import { removeNullOrUndefinedFromArray } from '../../utils';
import { orderHistoryPanelP, viewPanelP, widgetBarP } from '../../../modules/property/helpers/position.constant';
import withElementPositioningAndVisibility from '../../../modules/property/hoc/withElementsPositioningAndVisibility';
import { ElementName } from '../../../modules/property/types/element-position-visibility.types';

const DOUGHNUT = 'doughnut';
export let mapClickHandlerToTrackDrawPointsForHistory = null;

const { Text } = Typography;
class MapToolsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeTool: 'select',
      isConfirmButtonDisabled: false,
      polygons: [],
      parcelToDelete: null,
      feedbackFileList: [],
      isModalVisible: false,
      feedbackDesc: '',
      finishMapToolModal: false,
      revisionCounter: 0,
      disabledTools: false,
      selectedKey: 'select',
      openSubMenu: '',
      isToolStart: false,
      undoDisable: false,
      redoDisable: false,
      lastEditedLayers: [],
      toolBarTopPosition: '50px',
    };

    this.setActiveTool = _debounce(this.setActiveTool, 500);
  }

  handleUndoRedo = async (type) => {
    const {
      drawingFeaturePointHistoryUndoStack,
      drawingFeaturePointHistoryRedoStack,
    } = this.props;
    let that = this;

    if (type === 'undo') {
      trackEvents('map-tools__undo');
      if (drawingFeaturePointHistoryUndoStack.length)
        that.props.performPointUndoOperation();
      else {
        that.props.clearAllMultiSelectedData();
        await that.props.popFromUndoStack(that.props.selectedLayer.id);
      }
    } else if ('redo') {
      trackEvents('map-tools__redo');
      if (drawingFeaturePointHistoryRedoStack.length)
        that.props.performPointRedoOperation();
      else {
        that.props.clearAllMultiSelectedData();
        that.props.popFromRedoStack(that.props.selectedLayer.id);
      }
    } else {
      NotifyError('Something went wrong with undo redo');
    }

  };

  componentDidMount = async () => {
    let that = this;

    that.setState({
      toolBarTopPosition: that.props.isNotificationBarVisible
        ? '100px'
        : '50px',
    });
    this.updateUndoDisable();
    this.updateRedoDisable();
    this.updateLastEdited();
    that.applyShortCutOperation();
    setTimeout(() => {
      that.setActiveTool('select', that.props.selectedLayer);
    }, 1000);
  };

  componentDidUpdate = (prevProps) => {
    let that = this;
    const {
      selectedLayer,
      activeTool,
      layerList,
      multiData,
      isNotificationBarVisible,
    } = that.props;

    if (prevProps.isNotificationBarVisible !== isNotificationBarVisible) {
      that.setState({
        toolBarTopPosition: isNotificationBarVisible ? '100px' : '50px',
      });
    }
    if (
      +prevProps.selectedLayer?.id !== +that.props.selectedLayer?.id ||
      prevProps.activeTool !== that.props.activeTool
    ) {
      const attributesMulti = multiData || {};
      if (!Object.keys(attributesMulti).length) {
        layerList.forEach((layer) => {
          if (layer?.layerId !== selectedLayer?.id) {
            this.props.resetLayerStyles(_get(selectedLayer, 'id'), true);
          }
        });
      }
      if (activeTool === 'modify') {
        if (
          _get(that.props.featureType[_get(selectedLayer, 'name')], 'type') ===
            FeatureType.LINE ||
          _get(that.props.featureType[_get(selectedLayer, 'name')], 'type') ===
            FeatureType.PATH
        ) {
          that.setActiveTool(
            activeTool,
            selectedLayer,
            prevProps.selectedLayer
          );
        } else {
          that.toolPermissions(
            activeTool,
            _get(that.props.featureType[_get(selectedLayer, 'name')], 'type'),
            selectedLayer,
            prevProps.selectedLayer
          );
        }
      } else if (activeTool === 'slice') {
        that.toolPermissions(
          activeTool,
          _get(that.props.featureType[_get(selectedLayer, 'name')], 'type'),
          selectedLayer,
          prevProps.selectedLayer
        );
      } else if (activeTool === 'cut') {
        that.toolPermissions(
          activeTool,
          _get(that.props.featureType[_get(selectedLayer, 'name')], 'type'),
          selectedLayer,
          prevProps.selectedLayer
        );
      } else if (activeTool === 'doughnut') {
        that.toolPermissions(
          activeTool,
          _get(that.props.featureType[_get(selectedLayer, 'name')], 'type'),
          selectedLayer,
          prevProps.selectedLayer
        );
      } else {
        that.setActiveTool(activeTool, selectedLayer, prevProps.selectedLayer);
      }
    }
    if (prevProps.activeTool !== that.props.activeTool) {
      const { selectedView, featureType, selectedLayer, mapRotateData } =
        that.props;
      if (activeTool !== 'new_note') {
        trackEvents('map-tools__select', {
          toolType:
            activeTool === 'slice'
              ? 'Line Split'
              : activeTool === 'doughnut'
              ? 'Lasso Split'
              : activeTool,
          layerName: _get(selectedLayer, 'name'),
          orderId: selectedView.orderId,
          viewId: selectedView.viewId,
          isBaseView: selectedView.isBaseView,
          isEditable: selectedView.isEditable,
          layerType: featureType[selectedLayer?.name]?.type,
          featureOwner: featureType[selectedLayer?.name]?.maintainer,
          featureType: featureType[selectedLayer?.name]?.category,
          isRotate: mapRotateData?.isRotate,
        });
      }
    }

    if (
      prevProps.stackOperationLoading !== this.props.stackOperationLoading ||
      prevProps.undoStack !== this.props.undoStack ||
      prevProps.autoSaveRequestTrack !== this.props.autoSaveRequestTrack ||
      prevProps.selectedLayer !== this.props.selectedLayer ||
      prevProps.drawingFeaturePointHistoryUndoStack !==
        this.props.drawingFeaturePointHistoryUndoStack
    ) {
      this.updateUndoDisable();
    }

    if (
      prevProps.stackOperationLoading !== this.props.stackOperationLoading ||
      prevProps.redoStack !== this.props.redoStack ||
      prevProps.autoSaveRequestTrack !== this.props.autoSaveRequestTrack ||
      prevProps.selectedLayer !== this.props.selectedLayer ||
      prevProps.drawingFeaturePointHistoryRedoStack !==
        this.props.drawingFeaturePointHistoryRedoStack
    ) {
      this.updateRedoDisable();
    }

    if (prevProps.undoStack !== this.props.undoStack) {
      this.updateLastEdited();
    }
  };

  updateLastEdited = () => {
    const { layersData, undoStack } = this.props;
    let formatted = Object.keys(undoStack).map((ele) => {
      let found = layersData.find((item) => item.layerId === parseInt(ele));
      let time = null;
      for (const key in undoStack) {
        if (key === ele) {
          const value = undoStack[key];
          // TODO: below 0th index can throw NPE. Handle it
          time = Object.keys(value).slice(-1)[0];
          break;
        }
      }
      if (!found) {
        return;
      }
      return {
        time: parseInt(time),
        layerType: found.featureType,
        name: found.name,
        key: found.name,
        fillColor: found.style.fillColor,
        icon: found.style.symbolType,
        symbolColor: found.style.symbolColor,
        stroke: found.style.stroke,
        strokeColor: found.style.color,
        layerId: found.layerId,
      };
    });
    formatted.sort((a, b) => {
      return b.time - a.time;
    });

    formatted = removeNullOrUndefinedFromArray(formatted);

    this.setState({ lastEditedLayers: formatted });
  };

  updateUndoDisable = () => {
    const {
      stackOperationLoading,
      undoStack,
      autoSaveRequestTrack,
      selectedLayer,
      drawingFeaturePointHistoryUndoStack,
    } = this.props;

    let undoStackKeys = Object.keys(undoStack[selectedLayer?.id] || {});
    let autoSaveRequestKeys = Object.keys(
      JSON.parse(JSON.stringify(autoSaveRequestTrack)) || {}
    );

    if (
      stackOperationLoading ||
      autoSaveRequestKeys.length ||
      (!undoStackKeys.length && !drawingFeaturePointHistoryUndoStack.length)
    ) {
      this.setState({ undoDisable: true });
    } else {
      this.setState({ undoDisable: false });
    }
  };

  updateRedoDisable = () => {
    const {
      stackOperationLoading,
      redoStack,
      autoSaveRequestTrack,
      selectedLayer,
      drawingFeaturePointHistoryRedoStack,
    } = this.props;

    let redoStackKeys = Object.keys(redoStack[selectedLayer?.id] || {});
    let autoSaveRequestKeys = Object.keys(
      JSON.parse(JSON.stringify(autoSaveRequestTrack)) || {}
    );

    if (
      stackOperationLoading ||
      autoSaveRequestKeys.length ||
      (!redoStackKeys.length && !drawingFeaturePointHistoryRedoStack.length)
    ) {
      this.setState({ redoDisable: true });
    } else {
      this.setState({ redoDisable: false });
    }
  };

  /**
   * This method initiates the point level undo redo tracking when an interaction in added to map and tracks the added point into redux
   * @param interaction any map draw interaction
   */
  interactionsDrawingPointLevelTracking = (interaction) => {
    let that = this;
    that.props.setActiveToolRef(interaction);
    that.props.drawInteractionClearPointLevelHistory();
    if (mapClickHandlerToTrackDrawPointsForHistory)
      unByKey(mapClickHandlerToTrackDrawPointsForHistory);
    mapClickHandlerToTrackDrawPointsForHistory = this.props.mapRef.on(
      'click',
      function () {
        if (interaction) interaction.changed();
      }
    );
    interaction.on('change', function (e) {
      let { sketchCoords_, finishCoordinate_ } = e.target;
      if (sketchCoords_) {
        if (Array.isArray(sketchCoords_[0][0]))
          sketchCoords_ = sketchCoords_[0];
        if (
          sketchCoords_?.length > 1 &&
          Array.isArray(sketchCoords_[0]) &&
          finishCoordinate_
        ) {
          that.props.drawInteractionAddPointToTrackHistory(
            sketchCoords_[sketchCoords_.length - 2]
          );
        }
      }
    });
  };

  toolPermissions = (activeTool, type, selectedLayer, prevSelectLayer) => {
    let that = this;
    switch (type) {
      case FeatureType.POINT:
        that.setActiveTool('select', selectedLayer);
        break;
      case FeatureType.LINE:
        that.setActiveTool('slice', selectedLayer, prevSelectLayer);
        break;
      case FeatureType.PATH:
        that.setActiveTool('select', selectedLayer);
        break;
      case 'doughnut':
        that.setActiveTool('select', selectedLayer);
        break;
      default:
        that.setActiveTool(activeTool, selectedLayer, prevSelectLayer);
    }
  };

  findLayer = (layerId) => {
    const { layerList } = this.props;
    let result;
    layerList &&
      layerList.length &&
      layerList.forEach((item) => {
        if (item.layerId === Number(layerId)) {
          result = item;
        }
      });
    return result;
  };

  /**
   *
   * @param components selected components on map layer
   * @param selectedLayer layer on which multiselection is performed
   */
  multiSelectCallBack = (
    /* all components selected with current virtual box */
    components,
    /* active layer */
    selectedLayer
  ) => {
    let that = this;
    let ext = {
      ctrlKey: true,
    };
    let selectedLayerObj = {
      ...selectedLayer,
      layerId: selectedLayer.id,
    };
    // Need to find layer details to form the key
    const layer = this.findLayer(selectedLayer.id);
    const key = layer.name + '&&' + layer.layerId + '&&' + layer.unit;
    // componentsAlreadySelected contains items that are already selected on screen
    let componentsAlreadySelected = this.props.attributesMulti || {};
    let selectedItemGeoJsonArray = componentsAlreadySelected?.[key]
      ? componentsAlreadySelected?.[key]
      : [];

    // the current selection may contain components that have already been selected previously
    // Hence this variable newlySelectedComponents keeps the list of newly selected components on that layer
    let newlySelectedComponents = [];
    for (let component of components) {
      let match = false;
      for (let selectedItem of selectedItemGeoJsonArray) {
        if (component.componentId === selectedItem.componentId) {
          match = true;
        }
      }
      if (!match) {
        newlySelectedComponents.push(component);
      }
    }
    // Finally concat the list of all selected components and push it to the store
    let finalList = [...selectedItemGeoJsonArray, ...newlySelectedComponents];
    this.props.setAttributeMultiPopupData({
      [key]: finalList,
    });
    that.props.singleMultiPopup(finalList, selectedLayerObj, ext, true);
  };

  applyShortCutOperation = () => {
    let that = this;
    // Modify
    that.props.shortCutRef.modify = async function () {
      await that.setActiveTool('modify', that.props.selectedLayer);
    };
    // Slice
    that.props.shortCutRef.slice = async function () {
      await that.setActiveTool('slice', that.props.selectedLayer);
    };
    // Cut
    that.props.shortCutRef.cut = async function () {
      await that.setActiveTool('cut', that.props.selectedLayer);
    };
    // Save
    that.props.shortCutRef.save = function () {
      that.handleMapToolSubmit();
    };
    // Exit Edit Mode
    that.props.shortCutRef.exitEditMode = function () {
      that.exitEditModeWithESC();
    };
    // Draw polygon, line ,point, path  shortcut
    that.props.shortCutRef.drawMode = async function () {
      await that.setActiveTool('draw', that.props.selectedLayer);
    };
  };

  setActiveTool = async (activeTool, layer, prevLayer = null) => {
    const that = this;
    const { selectedView } = that.props;
    let measureDivElement = document.getElementById('measure-div');
    if (
      activeTool !== 'select' &&
      activeTool !== 'new_note' &&
      activeTool !== 'measuretool'
    ) {
      removeDragBoxInteraction(that.props.mapRef);
      if (!selectedView?.isEditable) {
        that.props.toggleAddAfterLayer(true, false, false);
        that.props.setActiveTool('select');
        return;
      }
    } else if (mapClickHandlerToTrackDrawPointsForHistory) {
      //To remove point level tracking onclick interactions
      unByKey(mapClickHandlerToTrackDrawPointsForHistory);
      // setActiveDeActiveInteraction(that.props.mapRef, DragPan, true);
    }

    if (
      activeTool === 'select' ||
      activeTool === 'new_note' ||
      activeTool === 'measuretool' ||
      activeTool === 'drag'
    ) {
      //To remove point level tracking onclick interactions
      unByKey(mapClickHandlerToTrackDrawPointsForHistory);
    }
    this.setState({
      disabledTools: true,
    });
    if (that.props.clickFeatureAction) {
      that.props.clickFeatureAction({}, null, null);
    }

    if (layer) {
      that.props.setActiveTool(activeTool);
      removeCutInteraction(that.props.mapRef);
      removeLineSliceInteraction(that.props.mapRef);
      removeDoughnutInteraction(that.props.mapRef);
      removeDrawInteraction(that.props.mapRef);
      removeMeasureInteraction(that.props.mapRef);
      removeModifyInteraction(that.props.mapRef);
      removeSelectInteraction(that.props.mapRef);
      removeSnipInteraction(that.props.mapRef);
      toggleHoverOverlayPopoverInteraction(false);
      resetCrossLayerSnap(that.props.mapRef);
      that.props.toogleMeasureTool(0);

      if (this.props.isBulkView && activeTool === 'measuretool') {
        await new Promise((r) => setTimeout(r, 500));
      }

      if (measureDivElement) {
        measureDivElement.style.visibility = 'hidden';
      }
      that.props.resetLayerStyles(_get(prevLayer, 'id'), true);

      switch (activeTool) {
        case 'drag':
          that.removeNoteOverlay();
          toggleHoverOverlayPopoverInteraction(true);
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('drag');
          that.handleMapFeatureDrag(layer);
          break;
        case 'drag duplicate':
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          that.props.setActiveTool('drag');
          that.handleMapFeatureDrag(layer);
          break;
        case 'draw':
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('draw');
          that.handleMapPolygonCreation(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case FeatureType.LINE:
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('draw');
          that.handleMapPolygonCreation(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case FeatureType.PATH:
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('draw');
          await that.handleMapPolygonCreation(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case FeatureType.POINT:
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('draw');
          that.handleMapPolygonCreation(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case FeatureType.POLYGON:
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('draw');
          that.handleMapPolygonCreation(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case 'modify':
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('modify');
          that.handleMapPolygonModification(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case 'cut':
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('cut');
          that.handleMapPolygonReduction(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case 'select':
          that.removeNoteOverlay();

          this.props.setMapClassnames('select');
          toggleHoverOverlayPopoverInteraction(true);
          let selectedId = _get(this.props.selectedLayer, 'id');
          let sourceLayer = this.props.layerRefs[selectedId];
          const { featureListInfo } = that.props;
          const features = featureListInfo.data;
          const featureType = features
            .filter(
              (item) =>
                item.featureId === _get(this.props.selectedLayer, 'featureId')
            )
            .map((item) => item.type)?.[0];

          multiSelectTool(
            this.props.mapRef,
            sourceLayer,
            that.props.selectedLayer,
            this.multiSelectCallBack,
            this.findLayer(selectedId),
            featureType
          );
          this.props.resetLayerStyles(
            _get(that.props.selectedLayer, 'id'),
            true
          );
          break;
        case 'slice':
          that.removeNoteOverlay();
          this.unselectSelectedFeatures();
          this.props.setMapClassnames('cut');
          that.handleMapPolygonSlice(layer);
          break;
        case DOUGHNUT:
          this.unselectSelectedFeatures();
          this.props.setMapClassnames(DOUGHNUT);
          that.handleMapPolygonDisunion(
            layer,
            _get(that.props.featureType[_get(layer, 'name')], 'type')
          );
          break;
        case 'measuretool':
          this.unselectSelectedFeatures();
          that.props.toogleMeasureTool(1);
          trackEvents('map-tools__measure', {
            orderId: selectedView.orderId,
            viewId: selectedView.viewId,
          });
          this.props.setMapClassnames('measuretool');
          that.removeNoteOverlay();
          break;
        case 'new_note':
          toggleHoverOverlayPopoverInteraction(true);
          this.unselectSelectedFeatures();
          trackEvents('notes__create-new-note', {
            source: 'toolbar',
            orderId: selectedView.orderId,
            viewId: selectedView.viewId,
          });
          this.props.setMapClassnames('commenttool');
          that.props.setAttributePopupData(null);
          break;
        default:
          // that.setState({activeTool:'select'})
          // this.props.setMapClassnames('select');
          toggleHoverOverlayPopoverInteraction(true);
          break;
      }
    } else {
      this.props.setMapClassnames('select');
      toggleHoverOverlayPopoverInteraction(true);
    }

    setTimeout(function () {
      that.setState({
        disabledTools: false,
      });
    }, 400);
  };

  removeNoteOverlay = () => {
    if (this.props.setNoteActions) this.props.setNoteActions(false);
  };

  resetSelectedFeatures = () => {
    let that = this;
    const { layerList, featureListInfo } = that.props;
    const attributesMulti = this.props.multiData;
    const m =
      attributesMulti && Object.keys(attributesMulti)
        ? Object.keys(attributesMulti)[0]
        : null;
    if (!m) return;
    let newData = attributesMulti?.[m] ? attributesMulti?.[m] : [];
    newData.forEach(function (item) {
      that.props.layerRefs[m.split('&&')[1]] &&
        clickLayerHighlight(
          that.props.layerRefs[m.split('&&')[1]].getSource(),
          item.id,
          false,
          layerList,
          featureListInfo.data
        );
    });
    newData = {
      ...attributesMulti,
      [m]: [],
    };
    this.props.setAttributeMultiPopupData(newData);
  };

  unselectSelectedFeatures = () => {
    let that = this;
    const { layerList, featureListInfo } = that.props;
    const attributesMulti = this.props.attributesMulti;
    const m =
      attributesMulti && Object.keys(attributesMulti)
        ? Object.keys(attributesMulti)[0]
        : null;
    if (!m) return;
    let newData = attributesMulti?.[m] ? attributesMulti?.[m] : [];
    newData.forEach(function (item) {
      let layerId = m.split('&&')[1];
      that.props.layerRefs[layerId] &&
        clickLayerHighlight(
          that.props.layerRefs[layerId],
          item.id,
          false,
          layerList,
          featureListInfo.data
        );
    });
    newData = {
      ...attributesMulti,
      [m]: [],
    };
    this.props.setAttributeMultiPopupData(null);
  };

  setIsConfirmButtonDisabled = (value) => {
    this.setState({
      isConfirmButtonDisabled: value,
    });
  };

  setParcelToDelete = (value) => {
    this.setState({ parcelToDelete: value });
  };

  setIsModalVisible = (value) => {
    this.setState({
      isModalVisible: value,
    });
  };

  setFeedbackFileList = (value) => {
    this.setState({
      feedbackFileList: value,
    });
  };

  setFeedbackDesc = (value) => {
    this.setState({
      feedbackDesc: value,
    });
  };

  setFinishMapTool = (value) => {
    this.setState({
      finishMapToolModal: value,
    });
  };

  setRevisionCounter = (value) => {
    this.setState({
      revisionCounter: value,
    });
  };

  handleMapFeatureDrag = async (selectedLayer) => {
    const { selectedView, featureType } = this.props;
    let source = this.props.layerRefs[selectedLayer.id].getSource();
    let segmentPayload = {
      orderId: selectedView.orderId,
      viewId: selectedView.viewId,
      isBaseView: selectedView.isBaseView,
      isEditable: selectedView.isEditable,
      featureOwner: featureType[selectedLayer?.name].maintainer,
      geometryCategory: featureType[selectedLayer?.name].category,
    };
    this.props.setMapClassnames('drag');
    // dragInteractionActive(this.props.layerRefs[selectedLayer.id], this.props.mapRef, this.callback);
    await translateFeatureWithCpy(
      this.props.mapRef,
      this.props.layerRefs[selectedLayer.id],
      selectedLayer.name,
      this.callback,
      this.setDragMouseCursor,
      this.setSelectedFeature,
      segmentPayload,
      this.hasToolStart
    );
  };

  setSelectedFeature = (value) => {
    this.props.setAttributePopupData(null);
    if (value) {
      this.props.onSelect([value?.componentId]);
    } else {
      this.props.onSelect([]);
    }
  };

  handleMapToolSubmit = () => {
    let that = this;
    // When save button is click, all selected items should get unselected
    that.unselectSelectedFeatures();
    that.props.setAttributePopupData(null);
    that.setActiveTool('select', _get(this.props.selectedLayer, 'id'));
    that.props.forcedSyncUpdatedComponentToServers();
    that.props.setExtraState({
      expendedKeys: `${_get(this.props.selectedLayer, 'id')}`,
      selectedNodeKeys: [],
    });
    return false;
    //continue
    let reqData = {
      userJson: '',
      orderId: _get(selectedView, 'orderId'),
      areaJson: '',
      id: _get(selectedView, 'id'),
    };
    that.props
      .submitMapToolThunk(reqData)
      .then((result) => {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, result);
      })
      .catch((error) => {
        NotifyError(error);
      });
  };

  handleMapPolygonCreation = async (selectedLayer, type) => {
    let sourceLayer = this.props.layerRefs[selectedLayer.id];
    const { layersData } = this.props;
    let tempLayerData = _filter(layersData, function (obj) {
      return obj.layerId === parseInt(selectedLayer.id);
    })?.[0];
    this.selectionSubMenu(type);
    switch (type) {
      case FeatureType.POINT:
        // this.props.setMapClassnames('draw drawPoint');
        this.props.setActiveTool(FeatureType.POINT);
        await drawInteractionActive(
          sourceLayer,
          this.props.mapRef,
          'Point',
          this.callback,
          type,
          tempLayerData?.style,
          this.props.layerRefs,
          this.hasToolStart,
          {
            interactionsDrawingPointLevelTracking:
              this.interactionsDrawingPointLevelTracking,
          }
        );
        break;
      case FeatureType.POLYGON:
        this.props.setMapClassnames('draw drawPolygon');
        this.props.setActiveTool(FeatureType.POLYGON);
        await drawInteractionActive(
          sourceLayer,
          this.props.mapRef,
          'Polygon',
          this.callback,
          type,
          null,
          this.props.layerRefs,
          this.hasToolStart,
          {
            interactionsDrawingPointLevelTracking:
              this.interactionsDrawingPointLevelTracking,
          }
        );
        break;
      case FeatureType.PATH:
        // this.props.setMapClassnames('draw drawLine');
        this.props.setActiveTool(FeatureType.PATH);
        await drawInteractionActive(
          sourceLayer,
          this.props.mapRef,
          'LineString',
          this.callback,
          type,
          tempLayerData?.style,
          this.props.layerRefs,
          this.hasToolStart,
          {
            interactionsDrawingPointLevelTracking:
              this.interactionsDrawingPointLevelTracking,
          }
        );
        break;
      case FeatureType.LINE:
        // this.props.setMapClassnames('draw drawLine');
        this.props.setActiveTool(FeatureType.LINE);
        await drawInteractionActive(
          sourceLayer,
          this.props.mapRef,
          'LineString',
          this.callback,
          type,
          null,
          this.props.layerRefs,
          this.hasToolStart,
          {
            interactionsDrawingPointLevelTracking:
              this.interactionsDrawingPointLevelTracking,
          }
        );
        break;
    }
  };

  callback = (
    type,
    event,
    optionalId,
    updateAtOnce = false,
    componentLayer = null
  ) => {
    const { selectedLayer } = this.props;
    const layerId = componentLayer?.values_?.id ?? selectedLayer.id;

    switch (type) {
      case BULK_INTERACTION:
        let defaultTimeStamp = moment();
        this.props.componentUpdatedEvent(
          null,
          event,
          layerId,
          null,
          defaultTimeStamp,
          updateAtOnce
        );
        break;
      case DRAW_INTERACTION:
        this.props.componentUpdatedEvent(
          optionalId,
          { ...event, layerId: selectedLayer.id },
          selectedLayer.id
        );
        break;
      case MODIFY_INTERACTION:
        this.props.componentUpdatedEvent(
          optionalId,
          { ...event, layerId: selectedLayer.id },
          selectedLayer.id
        );
        break;
      case 'dragInteraction':
        this.props.componentUpdatedEvent(
          optionalId,
          { ...event, layerId: selectedLayer.id },
          selectedLayer.id
        );
        break;
      case 'cutInteraction':
        this.props.componentUpdatedEvent(
          optionalId,
          { ...event, layerId: selectedLayer.id },
          selectedLayer.id
        );
        break;
      case 'deleteInteraction':
        this.props.deleteComponent(
          {
            ...event,
            layerId: selectedLayer.id,
            options: { componentId: optionalId },
          },
          true
        );
        break;
      case 'snipInteraction':
        this.props.componentUpdatedEvent(
          optionalId,
          { ...event, layerId: selectedLayer.id },
          selectedLayer.id
        );
        break;
      case 'copyInteraction':
        // this.props.componentUpdatedEvent(optionalId,event);
        break;
    }
    this.props.drawInteractionClearPointLevelHistory();
  };

  handleMapPolygonModification = async (selectedLayer, type) => {
    let layer = this.props.layerRefs[selectedLayer.id];
    const { layersData } = this.props;
    let tempLayerData = _filter(layersData, function (obj) {
      return obj.layerId === parseInt(selectedLayer.id);
    })?.[0];
    this.props.setMapClassnames('modify');
    await modifyInteractionActive(
      layer,
      this.props.mapRef,
      type,
      this.callback,
      tempLayerData?.style,
      this.props.layerRefs,
      this.hasToolStart
    );
  };

  handleMapPolygonReduction = async (selectedLayer, type) => {
    let sourceLayer = this.props.layerRefs[selectedLayer.id];
    this.props.setMapClassnames('cut');
    await cutInteractionActive(
      sourceLayer,
      this.props.mapRef,
      type,
      this.callback,
      this.props.layerRefs,
      this.hasToolStart,
      {
        interactionsDrawingPointLevelTracking:
          this.interactionsDrawingPointLevelTracking,
      }
    );
  };

  handleMapPolygonSlice = async (selectedLayer) => {
    let sourceLayer = this.props.layerRefs[selectedLayer.id];
    let selectedLayerType = _get(
      this.props.featureType[_get(selectedLayer, 'name')],
      'type'
    );
    this.props.setMapClassnames('slice');
    if (selectedLayerType === FeatureType.LINE) {
      await lineSliceInteractionActive(
        sourceLayer,
        this.props.mapRef,
        'LineString',
        this.callback,
        this.props.layerRefs,
        this.hasToolStart,
        {
          interactionsDrawingPointLevelTracking:
            this.interactionsDrawingPointLevelTracking,
        }
      );
    } else {
      await snipInteractionActive(
        sourceLayer,
        this.props.mapRef,
        this.callback,
        this.props.layerRefs,
        this.hasToolStart,
        {
          interactionsDrawingPointLevelTracking:
            this.interactionsDrawingPointLevelTracking,
        }
      );
    }
  };

  handleSelectedTool = (event) => {
    const tool = event?.key;
    if (!this.props.allowed(tool)) {
      return;
    }
    let that = this;
    that.props.setActiveTool(tool);
    that.setState({ selectedKey: tool, openSubMenu: '' });
    // that.setActiveTool(event?.key, selectedLayer);
    that.setIsConfirmButtonDisabled(true);
  };

  onChangeHandle = (value) => {
    let that = this;
    const { selectedLayer, selectedView } = that.props;
    if (value?.length && value[0] === 'lineOpen') {
      if (!selectedView?.isEditable) {
        that.props.toggleAddAfterLayer(true, false, false);
        return;
      }
      let selectedValue = _get(selectedLayer, 'name');
      that.selectionSubMenu(
        _get(that.props.featureType[selectedValue], 'type')
      );
    }
  };

  selectionSubMenu = (type) => {
    let that = this;
    const { selectedLayer } = that.props;
    switch (type) {
      case FeatureType.LINE:
        that.setState({ openSubMenu: 'lineOpen' });
        // that.setActiveTool('line', selectedLayer);
        that.props.setActiveTool(FeatureType.LINE);
        break;
      case FeatureType.PATH:
        that.setState({ openSubMenu: 'lineOpen' });
        // that.setActiveTool('path', selectedLayer);
        that.props.setActiveTool(FeatureType.PATH);
        break;
      default:
        this.setState({ openSubMenu: ' ' });
    }
  };

  handleMapPolygonDisunion = (selectedLayer, type) => {
    const sourceLayer = this.props.layerRefs[selectedLayer.id];

    doughnutInteractionActive(
      sourceLayer,
      this.props.mapRef,
      type,
      this.callback,
      this.props.layerRefs,
      this.hasToolStart,
      {
        interactionsDrawingPointLevelTracking:
          this.interactionsDrawingPointLevelTracking,
      }
    );
  };

  toolIconRender = (menu, type, defaultIcon) => {
    if (menu === 'lineSubMenu') {
      if (type === FeatureType.PATH)
        return (
          <Tooltip placement='right' title={'Draw Path'}>
            {' '}
            <PathTool />
          </Tooltip>
        );
      if (type === FeatureType.LINE)
        return (
          <Tooltip placement='right' title={'Draw Line'}>
            {' '}
            <LineTool />
          </Tooltip>
        );
    }
    if (menu === 'cutSubMenu') {
      if (type === 'cut')
        return (
          <Tooltip placement='right' title={'Cut Tool'}>
            <CutTool />
          </Tooltip>
        );
      if (type === 'slice')
        return (
          <Tooltip placement='right' title={'Line Split Tool'}>
            <LineSplit />
          </Tooltip>
        );
      if (type === 'doughnut')
        return (
          <Tooltip placement='right' title={'Lasso Split Tool'}>
            <LassoSplit />
          </Tooltip>
        );
    }
    return defaultIcon;
  };

  proFeatureToolTip = (
    <Tooltip
      overlayClassName={'menu-tool-tip'}
      placement='right'
      overlayStyle={{ width: 250, maxWidth: '250px !important' }}
      overlayInnerStyle={{ borderRadius: 6 }}
      color={'white'}
      title={
        <List.Item.Meta
          title={
            <Text className={'mt-1 mb-0 mx-1'}>Pro Feature in Free Beta </Text>
          }
          description={
            <Text type='secondary' className={'mt-0 mb-1 mx-1'}>
              Soon to be only in organization subscriptions
            </Text>
          }
        />
      }
    >
      <span className={'pro-feature'}>
        <ProFeature />
      </span>
    </Tooltip>
  );

  exitEditModeWithESC = () => {
    let that = this;
    const { selectedLayer, activeTool } = that.props;
    const { isToolStart } = that.state;
    if (activeTool !== 'select' && isToolStart) {
      that.setActiveTool(activeTool, selectedLayer);
      that.hasToolStart(false);
    } else {
      that.handleMapToolSubmit();
    }
  };
  hasToolStart = (value) => {
    this.setState({
      isToolStart: value,
    });
  };

  updateTime = () => {
    let count = this.props.topologyWarning[selectedLayer?.id]?.count;
    setTopoWarningCounts(count);
    if (
      lastAutoSaved &&
      lastAutoSaved[selectedLayer?.id] &&
      moment(lastAutoSaved[selectedLayer?.id]).isValid() &&
      count
    ) {
      setAutoSavedTimeString(
        count ? (
          <Tooltip
            title={
              <ul
                style={{
                  paddingLeft: 20,
                  marginBottom: 0,
                  width: 250,
                  paddingRight: 20,
                }}
              >
                {topologyWarningStringsArray(
                  this.topologyWarning[selectedLayer?.id],
                  componentIndexMapping,
                  null,
                  props.selectedLayerType
                ).map((list) => (
                  <li style={{ fontSize: 12 }}>{list}</li>
                ))}
                <Title
                  level={5}
                  align={'right'}
                  style={{
                    color: 'white',
                    marginBottom: 0,
                    fontWeight: 600,
                    fontSize: 13,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.ignoreTopoChecks(selectedLayer?.id);
                    trackEvents('layerpanel__topocheck_ignore-multiple', {
                      viewId: props?.selectedView?.viewId,
                      orderId: props?.selectedView?.orderId,
                      geometryType:
                        props?.featureType[props?.selectedLayer?.name].type,
                      geometryCategory:
                        props?.featureType[props?.selectedLayer?.name].category,
                      layerName: props?.selectedLayer?.name,
                      featureOwner:
                        props?.featureType[props?.selectedLayer?.name]
                          .maintainer,
                      isBaseView: props?.selectedView?.isBaseView,
                      errorType: topologyWarningStringsArray(
                        this.topologyWarning[selectedLayer?.id],
                        componentIndexMapping,
                        null,
                        props.selectedLayerType
                      ),
                      triggerType: 'editing-card',
                      noOfComponents:
                        this.props?.layerComponents[props?.selectedLayer?.id]
                          .length,
                    });
                  }}
                >
                  Ignore All
                </Title>
              </ul>
            }
          >{`Autosaved ${moment(
            props.lastAutoSaved[selectedLayer?.id]
          ).fromNow()}${count ? ' (' + count + ' errors)' : ''}`}</Tooltip>
        ) : null
      );
    } else {
      setAutoSavedTimeString(
        count ? (
          <Tooltip
            title={
              <ul
                style={{
                  paddingLeft: 20,
                  marginBottom: 0,
                  width: 250,
                  paddingRight: 20,
                }}
              >
                {topologyWarningStringsArray(
                  this.topologyWarning[selectedLayer?.id],
                  componentIndexMapping,
                  null,
                  props.selectedLayerType
                ).map((list) => (
                  <li style={{ fontSize: 12 }}>{list}</li>
                ))}
                <Title
                  level={5}
                  align={'right'}
                  style={{
                    color: 'white',
                    marginBottom: 0,
                    fontWeight: 600,
                    fontSize: 13,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.ignoreTopoChecks(selectedLayer?.id);
                    trackEvents('layerpanel__topocheck_ignore-multiple', {
                      viewId: props?.selectedView?.viewId,
                      orderId: props?.selectedView?.orderId,
                      geometryType:
                        props?.featureType[props?.selectedLayer?.name].type,
                      geometryCategory:
                        props?.featureType[props?.selectedLayer?.name].category,
                      layerName: props?.selectedLayer?.name,
                      featureOwner:
                        props?.featureType[props?.selectedLayer?.name]
                          .maintainer,
                      isBaseView: props?.selectedView?.isBaseView,
                      errorType: topologyWarningStringsArray(
                        this.topologyWarning[selectedLayer?.id],
                        componentIndexMapping,
                        null,
                        props.selectedLayerType
                      ),
                      triggerType: 'editing-card',
                      noOfComponents:
                        this.props?.layerComponents[props?.selectedLayer?.id]
                          .length,
                    });
                  }}
                >
                  Ignore All
                </Title>
              </ul>
            }
          >
            Found {count} errors!
          </Tooltip>
        ) : null
      );
    }
  };

  render() {
    let that = this;
    const {} = that.state;

    const { selectedLayer, activeTool, showPaywall, currentPlan } = that.props;
    let selectedValue = _get(selectedLayer, 'name');

    const layerPanelWidth = getPanelWidth(
      this.props.visibleAttributesInLayerPanel
    );
    const notesPanelWidth = '350px';
    const pinnedExtraWidth = '248px';

    const applyPinnedExtraWidth = (value) => (
      this.props.isViewPanelPinned ? `calc(${value} - ${pinnedExtraWidth})` : value
    );
    
    const getMapToolbarWidthRegularView = () => {
      if (this.props.openFPanel && !this.props.isNotesPanelExpanded) {
        console.log(">>> width: case 1 ")
        return applyPinnedExtraWidth(`calc(100vw - ${layerPanelWidth})`);
      } 
      else if (
        !this.props.openFPanel &&
        this.props.isNotesPanelExpanded &&
        !this.props.showApprovedOrderDetails
      ) {
        console.log(">>> width: case 2 ")
        return applyPinnedExtraWidth(`calc(100vw})`);
      } 
      else if (this.props.openFPanel && this.props.isNotesPanelExpanded) {
        console.log(">>> width: case 3 ")
        return applyPinnedExtraWidth(
          `calc(100vw - ${sumOfPx([layerPanelWidth])})`
        );
      } else if (
        this.props.showApprovedOrderDetails &&
        !this.props.isNotesPanelExpanded
      ) {
        console.log(">>> width: case 4 ")
        return applyPinnedExtraWidth('calc(100vw - 280px)');
      } else if (
        this.props.showApprovedOrderDetails &&
        this.props.isNotesPanelExpanded
      ) {
        console.log(">>> width: case 5 ")
        return applyPinnedExtraWidth(
          `calc(100vw - ${sumOfPx(['280px'])})`
        );
      } else {
        console.log(">>> width: case 6 ")
        return applyPinnedExtraWidth('100vw');
      }
    };
    
    const getMapToolbarWidthBulkView = () => {
      if (!this.props.isNotesPanelExpanded) {
        return applyPinnedExtraWidth(
          `calc(100vw - ${decideLeftPaddingOfPanel()})`
        );
      }
      return applyPinnedExtraWidth(
        `calc(100vw - ${sumOfPx([decideLeftPaddingOfPanel(), notesPanelWidth])})`
      );
    };

    const decideLeftPaddingOfPanel = () => {
      const primaryPanelWidth = '270px';
      const viewSelectionPanelWidth = this.props.elementStates[ElementName.OrderDetailsPanelPosition].width + "px";
      const isOrderHistoryPanelVisible = this.props.isOrderHistoryPanelVisible;
      const applyExtraWidth = (value) =>
        this.props.isViewPanelPinned
          ? `calc(${value} + ${pinnedExtraWidth})`
          : value;

      if (isOrderHistoryPanelVisible) {
        return this.props.isViewPanelPinned
          ? `${
              widgetBarP.width + viewPanelP.width + orderHistoryPanelP.width
            }px`
          : `${widgetBarP.width + orderHistoryPanelP.width}px`;
      }

      if (this.props.showApprovedOrderDetails) {
        // return applyExtraWidth(viewSelectionPanelWidth);
        return this.props.elementStates[ElementName.MapToolBarPosition].position.left + "px";
      }

      if (!this.props.isBulkView) {
        // return applyExtraWidth(this.props.openFPanel ? layerPanelWidth : '');
        return this.props.elementStates[ElementName.MapToolBarPosition].position.left + "px";
      }
      if (this.props.isSecondaryPanelVisible) {
        return applyExtraWidth(sumOfPx([primaryPanelWidth, layerPanelWidth]));
      }

      // Return primaryPanelWidth with extra width if isViewPanelPinned is true
      return applyExtraWidth(primaryPanelWidth);
    };

    const maptoolBarWidth = this.props.isBulkView
      ? getMapToolbarWidthBulkView()
      // : getMapToolbarWidthRegularView();
      : `calc(100vw - ${decideLeftPaddingOfPanel()})`
    // const integer = sumOfPx(maptoolBarWidth);

    return (
      <div className='parcel-modify-confirm-panel'>
        <div
          className='floating-tools'
          id='floating-tools'
          style={{
            left: decideLeftPaddingOfPanel(),
            width: maptoolBarWidth,
            top: this.state.toolBarTopPosition,
          }}
        >
          <MapToolbar
            maptoolBarWidth={maptoolBarWidth}
            setPinnedTools={this.props.setPinnedTools}
            disableAllTools={this.props.disableAllTools}
            disableAllToolsTooltip={this.props.disableAllToolsTooltip}
            handleSelectedTool={that.handleSelectedTool}
            activeTool={[activeTool]}
            onChangeHandle={that.onChangeHandle}
            handleUndoRedo={that.handleUndoRedo}
            undoDisable={this.state.undoDisable}
            redoDisable={this.state.redoDisable}
            selectedLayer={selectedValue}
            lastEditedLayers={that.state.lastEditedLayers}
            layerList={this.props.layerList}
            setCurrentLayer={this.props.setCurrentLayer}
            expandAllTools={!this.props.isNotesPanelExpanded}
            onExpandTree={this.props.onExpandTree}
            addLayerHandler={this.props.addLayerHandler}
            isEssential={currentPlan === SubscriptionPlan.ESSENTIAL}
            showPaywall={showPaywall}
            SubscriptionChip={SubscriptionChip}
            topologyObjects={{
              topologyWarning: this.props.topologyWarning,
              topologyWarningStringsArray: topologyWarningStringsArray,
              layerComponents: this.props.layerComponents,
              ignoreTopoChecks: this.props.ignoreTopoChecks,
              displayComponentName: displayComponentName,
            }}
            onResetModifications={this.props.onResetModifications}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawingFeaturePointHistoryUndoStack:
      state.map.drawingFeaturePointHistoryUndoStack,
    drawingFeaturePointHistoryRedoStack:
      state.map.drawingFeaturePointHistoryRedoStack,
    orderOrganisation: state.order.orderOrganisation,
    mapClassName: state.user.mapClassName,
    featureListInfo: state.order.featureListInfo,
    activeTool: state.user.activeTool,
    layerList: state.order.layerList,
    attributesMulti: state.feature.attributeMultiPopupData,
    mapRotateData: state.order.mapRotateData,
    multiData: state.feature.attributeMultiPopupData,
    isNotesPanelExpanded: state.notes.isNotesPanelExpanded,
    visibleAttributesInLayerPanel: state.order.visibleAttributesInLayerPanel,
    isNotificationBarVisible: state.ui.notificationBar.visible,
    isSecondaryPanelVisible: state.ui.isSecondaryPanelVisible,
    topologyWarning: state.order.topologyWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditingRedux: (payload) => dispatch(setEditingRedux(payload)),
    setMapClassnames: (payload) => dispatch(setMapClassnames(payload)),
    submitMapToolThunk: (payload) => dispatch(submitMapToolThunk(payload)),
    toogleMeasureTool: (payload) => dispatch(toogleMeasureTool(payload)),
    setAttributePopupData: (payload) =>
      dispatch(setAttributePopupData(payload)),
    setActiveTool: (payload) => {
      dispatch(setActiveTool(payload));
    },
    setAttributeMultiPopupData: (payload) =>
      dispatch(setAttributeMultiPopupData(payload)),
    // Interactions Point Level Tracking Redux Actions
    drawInteractionAddPointToTrackHistory: (payload) =>
      dispatch(drawInteractionAddPointToTrackHistory(payload)),
    drawInteractionClearPointLevelHistory: (payload) =>
      dispatch(drawInteractionClearPointLevelHistory(payload)),
  };
};

export default withSubscription(
  WithMapToolGuard(
    withElementPositioningAndVisibility(
      connect(mapStateToProps, mapDispatchToProps)(MapToolsPanel)
      )
    )
);
