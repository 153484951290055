import { BETA_FEATURE_ACTIONS } from './action_types';

export const showFeed = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_FEED,
});

export const showAttributes = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_ATTRIBUTES,
});

export const showStreetView = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_STREET_VIEW,
});

export const showOrderQueue = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_ORDERQUEUE,
});

export const hideOrderRating = () => ({
  type: BETA_FEATURE_ACTIONS.HIDE_ORDER_RATING,
});

export const setBetaFeatureDefault = () => ({
  type: BETA_FEATURE_ACTIONS.DEFAULT,
});

export const addMoreBulkOrder = () => ({
  type: BETA_FEATURE_ACTIONS.ADD_MORE_BULK_ORDER,
});

export const uploadGeoJSON = () => ({
  type: BETA_FEATURE_ACTIONS.UPLOAD_GEO_JSON,
});

export const allowParcelRepair = () => ({
  type: BETA_FEATURE_ACTIONS.ALLOW_PARCEL_REPAIR,
});

export const allowImageRepair = () => ({
  type: BETA_FEATURE_ACTIONS.ALLOW_IMAGE_REPAIR,
});

export const allowTabulaUpload = () => ({
  type: BETA_FEATURE_ACTIONS.ALLOW_TABULA_UPLOAD,
});

export const showDownloadGeojson = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_DOWNLOAD_GEOJSON,
});

export const copyViewId = () => ({
  type: BETA_FEATURE_ACTIONS.COPY_VIEW_ID,
});

export const allowKmlExport = () => ({
  type: BETA_FEATURE_ACTIONS.ALLOW_KML_EXPORT,
});

export const isNearmapPreviewEnabled = () => ({
  type: BETA_FEATURE_ACTIONS.IS_NEARMAP_PREVIEW_ENABLED,
});

export const isServiceItem = () => ({
  type: BETA_FEATURE_ACTIONS.IS_SERVICE_ITEM,
});

export const showReports = () => ({
  type: BETA_FEATURE_ACTIONS.SHOW_REPORTS,
});

export const enableAddWorkspace = () => ({
  type: BETA_FEATURE_ACTIONS.ENABLE_ADD_WORKSPACE
})

export const isEstimationsEnabled = () => ({
  type: BETA_FEATURE_ACTIONS.IS_ESTIMATIONS_ENABLED,
});