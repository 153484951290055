import { SVGProps, useState } from 'react';
import { Button, Row } from 'antd';
import Text from '@/components/elements/text/Text';

interface HighlightViewProps {
  title?: string;
  image: SVGProps<any>;
  onClick?: () => void;
  className?: string;
}

const HighlightView = ({
  title,
  image,
  onClick,
  className,
}: HighlightViewProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Button
      className={className}
      onClick={onClick}
    >
      <Row justify='center' className='mb-2 mt-2'>
        {image}
      </Row>
      <Row justify='center'>
        <Text type='p36' color='gray-shade'>
          {title}
        </Text>
      </Row>
    </Button>
  );
};

export default HighlightView;
