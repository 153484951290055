import { useEffect } from 'react';
import { NotificationBar } from '@siterecon/sr-component/dist/sr-component-library';
import ExclamationDark from '../../../../assets/icons/ExclamationDark';
import useNotificationBar from './useNotificationBar';
import { useSubscription } from '../../../../modules/subscription/hooks';
import { NotificationBarType } from './types';
import { SubscriptionState } from '../../../subscription/helpers/enum';

const TYPE = NotificationBarType.PaymentFailed;

const PaymentFailedNotificationBar = () => {
  const { isVisible, show, hide } = useNotificationBar();

  const { subscriptionState, showSubscriptionWidget } = useSubscription();

  const handleUpdatePaymentMethod = () => {
    showSubscriptionWidget();
  };

  const handleClose = () => hide(TYPE);

  useEffect(() => {
    if (!subscriptionState) return;

    if (subscriptionState === SubscriptionState.PAYMENT_FAILED) {
      show(TYPE);
    }
  }, [subscriptionState]);

  if (!isVisible(TYPE)) {
    return null;
  }

  return (
    <>
      <NotificationBar
        icon={<ExclamationDark />}
        message="Billing overdue as the payment has failed. Please update payment method for uninterrupted services. We will retry again the next day."
        action={{
          label: 'Update Payment Method',
          onClick: handleUpdatePaymentMethod,
        }}
        onClose={handleClose}
      />
    </>
  );
};

export default PaymentFailedNotificationBar;
