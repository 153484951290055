import { HTMLProps } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { truncateString } from '@/helpers/utilities';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import Text from '@/components/elements/text/Text';
import { Box } from '@/modules/common/elements';
import './style.less';

interface MoreChipProps {
  listToRender?: CheckboxValueType[];
  type?: string;
  fallback?: string;
  fallbackProps?: HTMLProps<any>;
  truncateChars?: number;
}

const MoreChip = ({
  listToRender,
  type = 'p8',
  fallback,
  fallbackProps,
  truncateChars = 11,
}: MoreChipProps) => {
  const getTruncatedString = () => {
    if (!listToRender?.length) return '';

    const maxLength =
      listToRender.length > 1 ? truncateChars : truncateChars + 8;

    return truncateString(listToRender[0], maxLength);
  };

  const tooltipText = (
    <Box flex direction='column'>
      {listToRender?.map((item, index) => (
        <Text type='p10' color='white' className='my-1' key={index}>
          {item}
        </Text>
      ))}
    </Box>
  );

  if (fallback && !listToRender?.length) {
    return (
      <Row justify='start'>
        <Text type={type} {...fallbackProps}>
          {fallback}
        </Text>
      </Row>
    );
  }

  return (
    <Tooltip
      title={tooltipText}
      placement='right'
      overlayClassName='more-btn-tooltip'
    >
      <Row justify='start'>
        {listToRender && (
          <Col className='mr-1'>
            <Text type={type} color='dark-gray'>
              {`${getTruncatedString()}`}
            </Text>
          </Col>
        )}
        {listToRender && listToRender?.length > 1 && (
          <Col>
            <Button className='default-value-more-btn'>
              <Text type='p80' color='link'>
                {`+${listToRender.length - 1} more`}
              </Text>
            </Button>
          </Col>
        )}
      </Row>
    </Tooltip>
  );
};

export default MoreChip;
