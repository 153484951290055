import React from 'react';
import CoWidgetBar from '../../../PlasmicComponents/CoWidgetBar';
import { useWidgetBarTabContext } from '../../../../modules/property/hooks/useWidgetBar';
import { WidgetBarTabs } from '../../../types';
import useViewSelectionOrCreationUtil from '../../../../modules/property/hooks/useViewSelectionOrCreationUtil';
import { useOrderStatus } from '../../../../modules/project/guards/OrderStatus';
import { getPlasmicOrderStatus } from '../utils/helpers';
import { widgetBarP } from '../../../../modules/property/helpers/position.constant';
import { createPortal } from 'react-dom';
import { isThisDeviceIpad } from '../../../../events/constants';

interface CoWidgetBarIntegrationProps {
  onChange: (tab: WidgetBarTabs) => void;
  diabledTabs: any[];
  isSharedPageLink: boolean
  className: string;
}

const CoWidgetBarIntegration = ({
  onChange,
  diabledTabs,
  isSharedPageLink,
  className,
}: CoWidgetBarIntegrationProps) => {
  const { activeTabs, handleWidgetBarTabChange, selectedTab } = useWidgetBarTabContext();

  const orderStatus = useOrderStatus();
  const { handleEstimationViewClick, handleStaticViewClick } =
    useViewSelectionOrCreationUtil();

  const handleChange = (tab: WidgetBarTabs) => {
    if (tab === WidgetBarTabs.ESTIMATION) {
      handleEstimationViewClick();
    } else if (tab === WidgetBarTabs.LAYERS) {
      handleStaticViewClick();
    }

    handleWidgetBarTabChange(tab, selectedTab);

    onChange(tab);
  };

  return <CoWidgetBar
    className={className ? className : ''}
    orderStatus={getPlasmicOrderStatus(orderStatus)}
    activeTab={activeTabs}
    disabledTabs={diabledTabs}
    onChange={handleChange}
    style={{
      position: 'fixed',
      height: isThisDeviceIpad ? 'calc(100% - 50px)' : 'calc(100vh - 50px)',
      borderRight: '0.5px solid #DDD',
      borderTop: '0.5px solid #DDD',
      bottom: 0,
      top: 50,
      zIndex: 1000,
      width: widgetBarP.width,
      ...widgetBarP.position,
    }}
    isSharedLinkPage={isSharedPageLink}
  />
};

export default CoWidgetBarIntegration;
