import React, { ReactNode, useEffect, useState } from 'react';
import { Modal, Spin, Tabs } from 'antd';
import { Box } from '@siterecon/sr-styles/dist/elements';
import EstimationLayerPanel from '../EstimationLayerPanel';
import './styles.less';
import { useEstimationViewContext } from '../../../../../modules/property/context/EstimationView/context';
import { useElementPositioningContext } from '../../../../../Contexts/ElementPositioningContext';
import { EstimationTabs } from '../types';
import { NumericId, ViewType } from '@/modules/common/types';
import useViewType from '../../../../../modules/project/guards/ViewGuard/useViewType';
import EmBetaBreakdownTab from '../../../../PlasmicComponents/EmBetaBreakdownTab';
import { WidgetBarTabs } from '../../../../types';
import { useWidgetBarTabContext } from '../../../../../modules/property/hooks/useWidgetBar';
import { CTAType, notifyCTA } from '../../../../../modules/property/api';
import useAuth from '../../../../../modules/auth/hooks';
import useOrganization from '../../../../../modules/auth/hooks/organization';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { FFEstimationView } from '../FFEstimationView/FFEstimationView';
import { toEstimationFFData } from '../../../../../modules/property/transformers/service-item.transformers';
import EmServiceMapZeroCta from '../../../../PlasmicComponents/EmServiceMapZeroCta';
import EmZeroServiceItem from '../../../../PlasmicComponents/EmZeroServiceItem';
import { BetaFeature, UserRole } from '../../../../../modules/common/types';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import CoEstimationPaywall from '../../../../PlasmicComponents/CoEstimationPaywall';
import RequestReceivedModal from '../RequestReceivedModal';
import { trackEvents } from '../../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../../segment';
import useRole from '../../../../../modules/auth/guards/RoleGuard/useRole';
import { isThisDeviceIpad } from '../../../../../events/constants';

interface EstimationViewPanelProps {
  viewsList?: any;
  currentViewData?: any;
  showViewSelectionPanel?: () => void;
  setLastSavedTimeText: (text: string) => void;
  activeEstimationTab: EstimationTabs;
  setActiveEstimationTab: (text: EstimationTabs) => void;
  setShowLegacyEstimationViewAlert: (show: boolean) => void;
  openFPanel: boolean;
  toggleViewpanelPin: (val: boolean) => void;
  linkedView: string;
  switchToLinkedViewHandler: () => void;
}

export interface TabObj {
  value: string;
  icon?: any;
  label?: string;
}

const { TabPane } = Tabs;

const LEARN_MORE_DOC: string =
  'https://siterecon.notion.site/NEW-Estimator-in-a-box-115ab44698b44e9e99576cb1288d8e11?pvs=4';
const BOOK_A_DEMO: string = 'https://meetings.hubspot.com/utkarsh18';

const EstimationViewPanel: React.FC<EstimationViewPanelProps> = ({
  showViewSelectionPanel,
  currentViewData,
  viewsList,
  setLastSavedTimeText,
  activeEstimationTab,
  setActiveEstimationTab,
  setShowLegacyEstimationViewAlert,
  openFPanel,
  toggleViewpanelPin,
  linkedView,
  switchToLinkedViewHandler,
}) => {
  enum TabWidth {
    SERVICE_MAP = '456px',
    ESTIMATION = isThisDeviceIpad ? '744px' : '868px',
    BREAKDOWN = '868px',
    INVOICE = 'calc(100vw - 56px)',
  }

  enum TabLabel {
    SERVICE_MAP = 'Service Map',
    ESTIMATION = 'Estimation',
    BREAKDOWN = 'Analytics',
    INVOICE = 'Export',
  }

  const [panelWidth, setPanelWidth] = useState(TabWidth.SERVICE_MAP);

  const {
    data: serviceItemData,
    layers,
    isServiceItemsListFetching,
    isLayerDataFetching,
  } = useEstimationViewContext();

  const isEstimationsEnabled = useBetaRelease(BetaFeature.Estimations);

  const currentViewType = useViewType();

  const { role } = useRole();

  const isViewerOrGuest = [UserRole.Viewer, UserRole.Guest].includes(role);

  const { handleWidgetBarTabChange, selectedTab } = useWidgetBarTabContext();

  const viewId = useSelector<IStore, NumericId | null>((state) => {
    return state.order.currentViewData?.viewId ?? null;
  });

  const [activeFFPage, setActiveFFPage] = useState(0);

  const [isRequestReceivedPopupVisible, setRequestReceivedPopupVisible] =
    useState(false);

  useEffect(() => {
    if (activeEstimationTab === EstimationTabs.ESTIMATION) {
      setActiveFFPage(0);
    } else if (activeEstimationTab === EstimationTabs.INVOICE) {
      setActiveFFPage(2);
    } else if (activeEstimationTab === EstimationTabs.BREAKDOWN) {
      setActiveFFPage(1);
    }
    else {
      setActiveFFPage(0);
    }
  }, [activeEstimationTab]);

  const user = useAuth();
  const org = useOrganization();

  const {
    setEstimationTabBreakdownTabOpen,
    setEstimationTabInvoiceTabOpen,
    setEstimationViewEstimationTabOpen,
    setEstimationViewServiceMapTabOpen,
    setNormalLayerPanelOpen,
  } = useElementPositioningContext();

  useEffect(() => {
    return () => {
      setEstimationViewServiceMapTabOpen(false);
      setEstimationTabInvoiceTabOpen(false);
      setEstimationViewEstimationTabOpen(false);
      setEstimationTabBreakdownTabOpen(false);
      setActiveFFPage(0);
    };
  }, []);

  useEffect(() => {
    setEstimationViewServiceMapTabOpen(true);
    setNormalLayerPanelOpen(false);
    handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
    if (serviceItemData === null) {
      console.log('serviceItemData is null');
      setLastSavedTimeText('');
    }
  }, [currentViewData?.viewId, serviceItemData]);

  useEffect(() => {
    handleTabChange(activeEstimationTab);
  }, [activeEstimationTab]);

  useEffect(() => {
    /** When view is changed, we want to reset the tab to SERVICE_MAP */
    if (activeEstimationTab !== EstimationTabs.SERVICE_MAP) {
      handleTabChange(EstimationTabs.SERVICE_MAP);
    }
  }, [selectedTab]);

  const tabLabelMap: Record<EstimationTabs, TabLabel> = {
    [EstimationTabs.SERVICE_MAP]: TabLabel.SERVICE_MAP,
    [EstimationTabs.ESTIMATION]: TabLabel.ESTIMATION,
    [EstimationTabs.BREAKDOWN]: TabLabel.BREAKDOWN,
    [EstimationTabs.INVOICE]: TabLabel.INVOICE,
  };

  const getTabLabel = (key: EstimationTabs): TabLabel => {
    const label = tabLabelMap[key];
    if (!label) {
      console.error('Invalid key');
    }
    return label;
  }

  const showLegacyEstimationViewAlertHandler = () => {
    if (currentViewType === ViewType.DYNAMIC) {
      setShowLegacyEstimationViewAlert(true);
      return;
    }
  };

  const handleTabChange = (key: EstimationTabs) => {
    const resetTabs = () => {
      setEstimationViewServiceMapTabOpen(false);
      setEstimationTabInvoiceTabOpen(false);
      setEstimationViewEstimationTabOpen(false);
      setEstimationTabBreakdownTabOpen(false);
    };

    const setTabsAndWidth = (
      serviceMapOpen,
      estimationOpen,
      breakdownOpen,
      invoiceOpen,
      width
    ) => {
      resetTabs();
      setEstimationViewServiceMapTabOpen(serviceMapOpen);
      setEstimationViewEstimationTabOpen(estimationOpen);
      setEstimationTabBreakdownTabOpen(breakdownOpen);
      setEstimationTabInvoiceTabOpen(invoiceOpen);
      if (currentViewType !== ViewType.DYNAMIC) setPanelWidth(width);
    };

    trackEvents(CreateOrderEvt.EstimationTabClicked, { tab: getTabLabel(key) })

    switch (key) {
      case EstimationTabs.SERVICE_MAP:
        setTabsAndWidth(true, false, false, false, TabWidth.SERVICE_MAP);
        break;
      case EstimationTabs.ESTIMATION:
        showLegacyEstimationViewAlertHandler();
        setTabsAndWidth(false, true, false, false, TabWidth.ESTIMATION);
        break;
      case EstimationTabs.BREAKDOWN:
        showLegacyEstimationViewAlertHandler();
        setTabsAndWidth(false, false, true, false, TabWidth.BREAKDOWN);
        break;
      case EstimationTabs.INVOICE:
        showLegacyEstimationViewAlertHandler();
        const isInvoiceTabOpen = isEstimationsEnabled;
        setTabsAndWidth(
          false,
          !isInvoiceTabOpen,
          false,
          isInvoiceTabOpen,
          isEstimationsEnabled ? TabWidth.INVOICE : TabWidth.ESTIMATION
        );
        toggleViewpanelPin(false);
        break;
      default:
        break;
    }

    if (currentViewType !== ViewType.DYNAMIC) {
      setActiveEstimationTab(key);
    }
  };
  /** Creating this function so that it can be passed to antd onChange without TS warning */
  const handleTabChangeWrapper = (activeKey: string) => {
    const tab = activeKey as EstimationTabs;
    handleTabChange(tab);
  };

  const notifyMeHandler = async (cta: CTAType, showToast: boolean = true) => {
    if (!user || !org) return;

    try {
      await notifyCTA(cta);
      if (showToast) {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Notification sent!');
      } else {
        setRequestReceivedPopupVisible(true);
      }
    } catch (err) {
      console.error(err);
      if (showToast) {
        showNotification(NOTIFICATIONS_TYPES.ERROR, 'Notification failed!');
      }
    }
  };

  const loader: ReactNode = (
    <Box flex align='middle' justify='center' placeholder={undefined}>
      <Spin size='large' />
    </Box>
  );

  const estimationLayerPanelContent = () => {
    if (layers && layers.length === 0) {
      return (
        <EmServiceMapZeroCta
          linkedView={linkedView}
          onGoToLinkedTakeOffView={switchToLinkedViewHandler}
          className='px-12'
        />
      );
    } else if (isServiceItemsListFetching || isLayerDataFetching) {
      return loader;
    } else if (!serviceItemData) {
      return (
        <EmZeroServiceItem
          onGoToLinkedTakeoff={switchToLinkedViewHandler}
          onRequestACallback={() =>
            notifyMeHandler(CTAType.RequestCatalogueUpdate, false)
          }
        />
      );
    } else {
      return <EstimationLayerPanel />;
    }
  };

  const estimationPaywall = (
    <CoEstimationPaywall
      onLearnMore={() => window.open(LEARN_MORE_DOC, '_blank')}
      onBookADemo={() => window.open(BOOK_A_DEMO, '_blank')}
    />
  );
  console.log(">>> xxserviceItemData: ", serviceItemData)
  console.log(">>> xxisEstimationsEnabled: ", isEstimationsEnabled)

  return (
    <Box
      className='sr-est-view-panel'
      style={{ width: openFPanel ? panelWidth : 0, height: '100%' }}
      placeholder={undefined}
    >
      <Tabs
        activeKey={activeEstimationTab}
        onChange={handleTabChangeWrapper}
        centered={false}
        className='left-aligned'
        tabBarGutter={8}
      >
        <TabPane tab={TabLabel.SERVICE_MAP} key={EstimationTabs.SERVICE_MAP}>
          {estimationLayerPanelContent()}
        </TabPane>
        <TabPane tab={TabLabel.ESTIMATION} key={EstimationTabs.ESTIMATION}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
        <TabPane tab={TabLabel.BREAKDOWN} key={EstimationTabs.BREAKDOWN}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
        <TabPane tab={TabLabel.INVOICE} key={EstimationTabs.INVOICE} disabled={isViewerOrGuest}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
      </Tabs>
      {isRequestReceivedPopupVisible && (
        <RequestReceivedModal
          visible={isRequestReceivedPopupVisible}
          setVisible={setRequestReceivedPopupVisible}
        />
      )}
      {serviceItemData
        ? isEstimationsEnabled && (
          <FFEstimationView
            assetBase={process.env.PUBLIC_URL + '/flutter/'}
            src={process.env.PUBLIC_URL + '/flutter/main.dart.js'}
            propertyId={currentViewData?.orderId}
            estimateViewId={currentViewData?.viewId}
            linkedTakeoffViewId={currentViewData?.linkedView}
            setActiveEstimationTab={setActiveEstimationTab}
            serviceItemMeasurementList={
              toEstimationFFData(serviceItemData, layers)[0]
            }
            featureDataList={toEstimationFFData(serviceItemData, layers)[1]}
            activePage={activeFFPage}
            showFlutterApp={[
              EstimationTabs.ESTIMATION,
              EstimationTabs.INVOICE,
              EstimationTabs.BREAKDOWN,
            ].includes(activeEstimationTab)}
            setLastSavedTimeText={setLastSavedTimeText}
          />
        )
        : activeEstimationTab !== EstimationTabs.SERVICE_MAP
          ? loader
          : null}
    </Box>
  );
};

export default EstimationViewPanel;
