import {
  OrderStatus,
  PlasmicOrderStatus,
} from '../../../../modules/common/types';

/** Pads given number or string with Leading zeroes to given max length. Ex. Input -> 3, Output -> 03 */
export const padWithZeros = (number: number | string, maxLength: 2): string => {
  const input =
    typeof number === 'number' ? String(Math.round(number)) : number;
  return input.padStart(maxLength, '0');
};

/** Convert singular/plural based on value, suffix should be singular */

export const addPluralSuffix = (number: number | string, suffix: string) => {
  const input = typeof number === 'string' ? Number(number) : number;
  return `${input === 1 ? suffix : suffix + 's'}`;
};

export const getPlasmicOrderStatus = (
  orderStatus: OrderStatus
): PlasmicOrderStatus | undefined => {
  const statusMap: { [key in OrderStatus]?: PlasmicOrderStatus } = {
    [OrderStatus.Draft]: PlasmicOrderStatus.Draft,
    [OrderStatus.MeasurementOngoing]: PlasmicOrderStatus.MeasurementOngoing,
    [OrderStatus.AwaitingApproval]: PlasmicOrderStatus.AwaitingApproval,
    [OrderStatus.Approved]: PlasmicOrderStatus.Approved,
    [OrderStatus.ResolvingFeedback]: PlasmicOrderStatus.ResolvingFeedback,
    [OrderStatus.SelfMeasured]: PlasmicOrderStatus.SelfMeasured,
    [OrderStatus.BoltFailed]: PlasmicOrderStatus.BoltFailed,
    [OrderStatus.BoltPreview]: PlasmicOrderStatus.BoltPreview,
    [OrderStatus.BoltApproved]: PlasmicOrderStatus.BoltApproved,
    [OrderStatus.BoltInVerification]: PlasmicOrderStatus.BoltInVerification,
    [OrderStatus.Confirmed]: PlasmicOrderStatus.Confirmed,
  };

  return statusMap[orderStatus];
};

export const getSupabaseApiKey = () => {
  const ENV = process.env.REACT_APP_ENV;

  let supabaseProjectKey = '';
  let apiKey = '';

  switch (ENV?.toString()) {
    case 'dev':
      supabaseProjectKey = 'rdtyhaskunbyoowlorxi';
      apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJkdHloYXNrdW5ieW9vd2xvcnhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDUzMzM3MzQsImV4cCI6MjAyMDkwOTczNH0.UOU1x3VKt4nsumpQYKSuI3Q-cW7tuEVMTd9d8qGBrvk'
      break;
    case 'uat':
      supabaseProjectKey = 'puefzwtgjresalccrsyw';
      apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB1ZWZ6d3RnanJlc2FsY2Nyc3l3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYyMTQyNjgsImV4cCI6MjAzMTc5MDI2OH0.C4ZkkeeIzNqGruuianNU38alc28VJC4yu9P2TQJVhTY';
      break;
    case 'qa':
      supabaseProjectKey = 'zvnpzukeprroeyqqwuqb';
      apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp2bnB6dWtlcHJyb2V5cXF3dXFiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQwOTM2NzQsImV4cCI6MjAzOTY2OTY3NH0.IdTSpsBBF_gqU7kTDi6zNL5LE0Nh68rTSXfC_GocqYI';
      break;
    case 'prod':
      // Add prod Key
      supabaseProjectKey = 'rdiyqhyvvoelfpwbejwn';
      apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJkaXlxaHl2dm9lbGZwd2JlanduIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjAwNzE1MzQsImV4cCI6MjAzNTY0NzUzNH0.zrCAmuZkXo2biaWVVHzG1A3qSKGsqS9uCO6OIx9ctbQ';
      break;
    default:
      supabaseProjectKey = 'rdiyqhyvvoelfpwbejwn';
      apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJkaXlxaHl2dm9lbGZwd2JlanduIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjAwNzE1MzQsImV4cCI6MjAzNTY0NzUzNH0.zrCAmuZkXo2biaWVVHzG1A3qSKGsqS9uCO6OIx9ctbQ';
  }

  return {
    supabaseProjectKey,
    apiKey,
  }
}

/** Get services names corresponding to services ids */
export const getServiceNamesFromIds = (serviceIds, servicesList) => {
  const serviceNames = [];

  if (!serviceIds || !servicesList) return serviceNames;

  servicesList.forEach(serviceType => {
    if (serviceType.service && Array.isArray(serviceType.service)) {
      serviceType.service.forEach(service => {
        if (serviceIds.includes(service.id)) {
          serviceNames.push(service.estimationService);
        }
      });
    }
  });

  return serviceNames;
}
