export const BETA_FEATURE_ACTIONS = {
  SHOW_FEED: 'FEED',
  SHOW_PATH_TOOL: 'SHOW_PATH_TOOL',
  SHOW_ATTRIBUTES: 'SHOW_ATTRIBUTES',
  SHOW_STREET_VIEW: 'SHOW_STREET_VIEW',
  SHOW_ORDERQUEUE: 'SHOW_ORDERQUEUE',
  HIDE_ORDER_RATING: 'HIDE_ORDER_RATING',
  DEFAULT: 'DEFAULT',
  ADD_MORE_BULK_ORDER: 'ADD_MORE_BULK_ORDER',
  UPLOAD_GEO_JSON: 'UPLOAD_GEO_JSON',
  ALLOW_IMAGE_REPAIR: 'ALLOW_IMAGE_REPAIR',
  ALLOW_PARCEL_REPAIR: 'ALLOW_PARCEL_REPAIR',
  ALLOW_TABULA_UPLOAD: 'ALLOW_TABULA_UPLOAD',
  SHOW_DOWNLOAD_GEOJSON: 'SHOW_DOWNLOAD_GEOJSON',
  COPY_VIEW_ID: 'COPY_VIEW_ID',
  ALLOW_KML_EXPORT: 'ALLOW_KML_EXPORT',
  IS_NEARMAP_PREVIEW_ENABLED: 'IS_NEARMAP_PREVIEW_ENABLED',
  IS_SERVICE_ITEM: 'IS_SERVICE_ITEM',
  SHOW_REPORTS: 'SHOW_REPORTS',
  ENABLE_ADD_WORKSPACE: 'ENABLE_ADD_WORKSPACE',
  IS_ESTIMATIONS_ENABLED: 'IS_ESTIMATIONS_ENABLED'
};
