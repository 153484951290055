import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { NumericId } from '../../../common/types';
import { Box } from '@siterecon/sr-styles/dist/elements';
import {
  ServiceItemAssignment,
  ServiceItemChange,
  SingleComponent,
} from '../../api/types';
import {
  getActionCenterData,
  getAssignmentPayload,
  getComponentsWithMutations,
  isUnassignRequest,
  mutateServiceItemAssignments,
} from '../../transformers/service-item.transformers';
import { assignServiceItems } from '../../api';
import EmServiceAssigments2 from '../../../../components/PlasmicComponents/EmServiceAssigments2';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { showNotification } from '../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../components/storybook/NotificationToast/types';
import { checkIfBulkOrder, openUrl } from '../../../../components/utils';
import { useServiceItems } from '../../hooks/useServiceItems';
import {
  setAttributePanelVisibility,
  setVisibleAttributesInLayerPanel,
} from '@/store/order/actions';
import './style.less';
import { cloneDeep, debounce } from 'lodash';
import { trackEvents } from '../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../segment';
import { useRole } from '@/modules/auth/guards/RoleGuard';

interface ServiceCatalogueProps {
  /** If provided, the action center would only display the serviceItems with that ID  */
  featureId?: NumericId;

  initialAssignments: ServiceItemAssignment[];

  components: SingleComponent[];

  orderHash?: string;

  onApply?: (components: Record<NumericId, SingleComponent>) => void;

  visible?: boolean;

  onSIDropdownToggle?: (visible: boolean) => void;

  layerName?: string /** In case there are no components, layer name is coming as undefined. Hence this is required */;
}

const ServiceCatalogue = ({
  featureId,
  initialAssignments,
  components,
  orderHash,
  onApply,
  visible = false,
  onSIDropdownToggle,
  layerName,
}: ServiceCatalogueProps) => {
  const dispatch = useDispatch();

  const waitingQueue = useRef<ServiceItemChange[] | null>(null);

  const processingQueue = useRef<ServiceItemChange[] | null>(null);

  const initialAssignmentsRef = useRef<ServiceItemAssignment[] | null>(null);

  const componentsRef = useRef<SingleComponent[] | null>(null);

  const viewId = useSelector<IStore, NumericId>(
    (state) => state.order.currentViewData?.viewId
  );

  const { data: features } = useSelector<IStore, any>(
    (state) => state.order.featureListInfo
  );

  const visibleAttributesInLayerPanel = useSelector<IStore, any[]>(
    (state) => state.order.visibleAttributesInLayerPanel
  );

  const isBulkOrder = checkIfBulkOrder();

  const { serviceItems: data } = useServiceItems();

  const [loading, setLoading] = useState<boolean>(false);

  const [toggledServiceItem, setToggledServiceItem] = useState<number>();

  const { role: userRole } = useRole();

  useEffect(() => {
    initialAssignmentsRef.current = initialAssignments;
  }, [initialAssignments]);

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const handleServiceItemsVisibilityChange = (visible: boolean) => {
    dispatch(setAttributePanelVisibility(visible));

    let newListOfVisibility: any[] = [];

    visibleAttributesInLayerPanel.map((item) => {
      if (item !== 'SERVICE_ITEM') {
        newListOfVisibility.push(item);
      }
    });

    if (visible) {
      newListOfVisibility.push('SERVICE_ITEM');
    }

    dispatch(setVisibleAttributesInLayerPanel(newListOfVisibility));
  };

  const debouncedApplyAssignments = useCallback(
    debounce(async (mutations: ServiceItemChange[]) => {
      if (processingQueue.current) {
        return;
      }

      const actionCenterComponentIds = componentsRef.current!.map(
        (component) => component.componentId
      );

      processingQueue.current = waitingQueue.current;
      waitingQueue.current = null;

      const updatedAssignments = mutateServiceItemAssignments(
        cloneDeep(initialAssignmentsRef.current!),
        mutations,
        actionCenterComponentIds
      );

      const assignmentPayload = getAssignmentPayload(
        cloneDeep(initialAssignmentsRef.current!),
        updatedAssignments,
        actionCenterComponentIds
      );

      const unAssignmentPayload = getAssignmentPayload(
        cloneDeep(initialAssignmentsRef.current!),
        updatedAssignments,
        actionCenterComponentIds,
        true
      );

      const requests: any[] = [];

      if (assignmentPayload.length > 0) {
        requests.push(
          assignServiceItems(viewId! as unknown as NumericId, assignmentPayload)
        );
      }

      if (unAssignmentPayload.length > 0) {
        requests.push(
          assignServiceItems(
            viewId! as unknown as NumericId,
            unAssignmentPayload,
            true
          )
        );
      }

      try {
        await Promise.all(requests);

        onApply?.(
          getComponentsWithMutations(componentsRef.current!, mutations)
        );

        handleServiceItemsVisibilityChange(true);

        trackEvents(CreateOrderEvt.ServiceItemAssignmentSuccess, {
          viewType: 'takeoff',
        });

        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          `Service item assignment updated successfully!`
        );
      } catch (error) {
        showNotification(
          NOTIFICATIONS_TYPES.ERROR,
          'Service item assignment failed, please refresh the page to be in sync'
        );
        console.error('Error while assigning service items', error);
      }

      setTimeout(() => {
        processingQueue.current = null;

        if (waitingQueue.current) {
          debouncedApplyAssignments(waitingQueue.current);
        }

        /** A small timeout to ensure setState actions are performed */
      }, 0);
    }, 100),
    []
  );

  const handleApply = async (mutations: ServiceItemChange[]) => {
    waitingQueue.current = mutations;
    debouncedApplyAssignments(waitingQueue.current);
  };

  const switchToMapPageHandler = () => {
    if (isBulkOrder && orderHash) {
      openUrl(`/project/${orderHash}`);
    }
  };

  if (!data) {
    return (
      <Box flex align='middle' justify='center' className='mt-6'>
        <Spin />
      </Box>
    );
  }

  return (
    <Box className='takeoff-layer-panel'>
      <EmServiceAssigments2
        serviceList={getActionCenterData(
          components,
          data,
          features,
          false,
          featureId,
          layerName
        )}
        onApplyButtonClick={handleApply}
        isBulkOrder={isBulkOrder}
        onSwitchToMainMap={switchToMapPageHandler}
        visible={visible}
        onDropDownToggle={onSIDropdownToggle}
        isLoadingActive={{ id: toggledServiceItem, loading }}
        onLastToggledId={(id: number) => setToggledServiceItem(id)}
        userRole={userRole}
      />
    </Box>
  );
};

export default ServiceCatalogue;
