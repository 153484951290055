import { NotifyError } from '@/helpers/notification-utils';
import { Button, Card, Col, Row, Tooltip } from 'antd';
import { _get } from '@/helpers/utilities/lodashUtils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../../assets/action-center/close_icon_cross.svg';
import { ReactComponent as CopyLayer } from '../../../assets/action-center/copy-jan-23.svg';
import { ReactComponent as DeleteLayer } from '../../../assets/action-center/delete-jan-23.svg';
import { ReactComponent as Setting } from '../../../assets/action-center/settings-jan-23.svg';
import { ReactComponent as Drag } from '../../../assets/action-center/drag-icon.svg';
import {
  setAttributeModalVisiblity,
  setAttributePopupData,
  setFeatureToVisible,
} from '@/store/attributeFeature/actions';
import '../feature-attribute.less';
import { ellipsis } from '../utils';
import { LayerStyling } from './layer-styling';
import './style.less';
import { OrderStatus, ViewType } from '@/modules/common/types';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { catchError, getAxiosInstance } from '@/helpers/utilities/api-utils';
import { useViewType } from '@/modules/project/guards/ViewGuard';
import AreaPerimeterInfo from '../components/area-perimeter-info';
import ServiceCatalogue from '../../../modules/property/components/service-item/ServiceCatalogue';
import { checkIfShareMapPage } from '../../utils';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import ActionCenterKebabMenu from './action-center-kebab-menu';
import { getServiceItemAssignmentsByLayerAndComponents } from '../../../modules/property/transformers/service-item.transformers';
import { trackEvents } from '../../../helpers/utilities';
import { CreateOrderEvt } from '../../../segment';

export const AttributePopup = (props) => {
  const axiosInstance = getAxiosInstance();
  const [isStyleVisible, setStyleVisible] = useState(false);
  const [isServiceItemVisible, setServiceItemVisible] = useState(false);
  const [layerComponents, setLayerComponents] = useState(null);
  const [editingAttribute, setEditingAttribute] = useState(null);
  const [styledPopupData, setPopupData] = useState(null);
  const [featureId, setFeatureId] = useState(null);
  const [featureStyle, setFeatureStyle] = useState(null);

  const dispatch = useDispatch();
  const viewType = useViewType();

  const attributeData = useSelector(
    (state) => state.feature.attributePopupData
  );

  const reduxLayerComponents = useSelector(
    (state) => state.order.layerComponents
  );

  const popupData = useMemo(() => {
    /** When layer is changed for huge properties, the `styledPopupData` takes time to be updated. In those scenarios, we need to show data directly from redux */
    return attributeData?.layerId === styledPopupData?.layerId
      ? styledPopupData
      : attributeData;
  }, [styledPopupData, attributeData]);

  const isSharePage = checkIfShareMapPage();

  const allServiceItemIds = useMemo(() => {
    const ids = [];

    for (const [layerName, components] of Object.entries(
      reduxLayerComponents || {}
    )) {
      for (const component of components || []) {
        if(component?.serviceItemIds?.length) {
          ids.push(...component.serviceItemIds);
        }
      }
    }

    return ids;
  }, [reduxLayerComponents]);

  useEffect(() => {
    setPopupData(attributeData);
  }, [attributeData]);

  useEffect(() => {
    if (popupData?.layerId) {
      init();
    }
  }, [popupData?.layerId, allServiceItemIds]);

  useEffect(() => {
    if (!featureId) {
      return;
    }

    axiosInstance
      .get(
        replaceParams(API_ENDPOINTS.PARTICULAR_FEATURE, {
          ':featureId': featureId,
        })
      )
      .then((res) => {
        setFeatureStyle(res.data.style);
      })
      .catch(catchError);
  }, [featureId]);

  const setLayerComponentsFromRedux = (layerName) => {
    if (!layerName) return;

    setLayerComponents(reduxLayerComponents[layerName] ?? []);
  };

  const init = async () => {
    setLayerComponentsFromRedux(popupData?.name);

    const layerFeatures = props.layersData
      .filter((layer) => layer.layerId === props.selectedLayer.id)
      .map((layer) => layer.featureId);

    if (layerFeatures.length > 0) {
      const featureId = layerFeatures[0];

      setFeatureId(featureId);
      setLayerComponents((prevState) =>
        prevState.map((component) => ({
          ...component,
          featureId,
        }))
      );
    }
  };

  const closeAttributePopup = () => {
    dispatch(setAttributePopupData(null));
  };

  const openAttributeModal = () => {
    props.toggleAddNewLayer(true);
    dispatch(setAttributeModalVisiblity(true));
    dispatch(
      setFeatureToVisible({
        name: popupData?.name,
        id: popupData?.featureId,
      })
    );
  };

  const handleStyler = async (value, name) => {
    const style = {
      ...popupData.style,
      [name]: name === 'opacity' ? value / 10 : value,
    };
    await setPopupData((p) => ({
      ...p,
      style,
    }));
    popupData.updateStyle(style, name);
  };

  const resetStyle = async (style) => {
    if (!style) {
      return;
    }
    await setPopupData((p) => ({
      ...p,
      style,
    }));
    popupData.updateStyle(style, 'color');
    popupData.updateStyle(style, 'stroke');
    popupData.updateStyle(style, 'opacity');
    popupData.updateStyle(style, 'fillColor');
  };

  const clickOutside = (e) => {
    if (e.target.localName !== 'input' && editingAttribute)
      setEditingAttribute(false);
  };

  const serviceItemDropdownToggleHandler = (visible) => {
    trackEvents(CreateOrderEvt.ServiceItemListToggle, { visible })
    if (visible) {
      setServiceItemVisible(!!visible);
      setStyleVisible(!visible);
    }
  };

  const handleLayerStylingVisibilityChange = (value) => {
    if (isServiceItemVisible) {
      setServiceItemVisible(false);
    }
    setStyleVisible(value);
  };

  const handleApply = (components) => {
    setLayerComponents((prevComponents) => {
      return prevComponents.map((component) => ({
        ...component,
        serviceItemIds:
          components[component.componentId]?.serviceItemIds ??
          component?.serviceItemIds ??
          [],
      }));
    });

    props.onServiceItemsChange?.(components);
  };

  let nIdsSelected =
    props.selectedLayer?.id && props.layerComponents[props.selectedLayer?.id]
      ? props.layerComponents[props.selectedLayer?.id].length
      : '--';

  const menuList = [
    {
      label: 'Layer Settings',
      icon: <Setting />,
      disabled:
        props.orderStatus === OrderStatus.AwaitingApproval ||
        props.orderStatus === OrderStatus.ResolvingFeedback,
      onClick: openAttributeModal,
    },
    {
      label: 'Copy Layer',
      icon: <CopyLayer />,
      disabled:
        props.orderStatus === OrderStatus.AwaitingApproval ||
        props.orderStatus === OrderStatus.ResolvingFeedback,
      onClick: popupData?.copy,
    },
    {
      label: 'Delete Layer',
      icon: <DeleteLayer />,
      disabled: !props.isEditableView,
      onClick: !props.isEditableView
        ? () => NotifyError('Operation not allowed!')
        : popupData?.delete,
    },
  ];

  return (
    <Card className='popup' onClick={clickOutside}>
      <Box
        style={{ height: 16 }}
        className='flex-justify-align-center w-100 drag-handle'
      >
        <Drag />
      </Box>
      <Row justify={'space-between'} className={'w-100'} align={'top'}>
        <Col span={20}>
          <Row style={{ marginBottom: 6 }}>
            <Tooltip title={popupData?.name}>
              <Typography
                fontSize={12}
                lineHeight='20px'
                fontWeight={600}
                className='mr-2'
              >
                {ellipsis(popupData?.name, 12)}
              </Typography>
              <Typography
                fontSize={12}
                lineHeight='20px'
                style={{ color: '#666' }}
              >
                {nIdsSelected}/{nIdsSelected} selected
              </Typography>
            </Tooltip>
          </Row>
          <Row>
            <AreaPerimeterInfo
              area={Number(popupData?.area)}
              areaUnit={popupData?.unit}
              perimeter={Number(popupData?.perimeter)}
            />
          </Row>
        </Col>
        <Col span={4}>
          <Row align={'top'} justify={'end'} wrap={false}>
            <ActionCenterKebabMenu menuList={menuList} />
            <Box flex>
              <Tooltip title='Close'>
                <Button
                  type={'link'}
                  icon={
                    <Close cursor='pointer' onClick={closeAttributePopup} />
                  }
                  style={{ width: 24, height: 24, display: 'flex' }}
                  className={`flex-justify-align-center ${
                    viewType === ViewType.DYNAMIC ? 'mb-1' : ''
                  }`}
                />
              </Tooltip>
            </Box>
          </Row>
        </Col>
      </Row>

      <div className='mt-3'>
        <LayerStyling
          layerType={props.geometryType}
          isVisible={isStyleVisible}
          setVisiblity={handleLayerStylingVisibilityChange}
          handleChange={handleStyler}
          name={popupData?.name}
          defaultValues={popupData?.style}
          key={popupData?.layerId}
          layerRefs={props.layerRefs}
          resetLayerStyles={props.resetLayerStyles}
          layerId={popupData?.layerId}
          layerSource={props.layerSource}
          resetStyle={() => resetStyle(featureStyle)}
        />
      </div>

      {layerComponents && !isSharePage && (
        <div className='end-content mt-2'>
          <ServiceCatalogue
            components={layerComponents}
            initialAssignments={getServiceItemAssignmentsByLayerAndComponents(
              props.layersData,
              props.layerComponents
            )}
            onApply={handleApply}
            visible={isServiceItemVisible}
            onSIDropdownToggle={serviceItemDropdownToggleHandler}
            layerName={attributeData?.name}
          />
        </div>
      )}
    </Card>
  );
};
