import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import './style.less';
import { Strings } from './utils/strings';

import { connect, ConnectedProps } from 'react-redux';
import { getQueueOrder } from './api';
import AddNewQueue from './components/AddNewQueue';
import SingleQueue from './components/Queue';
import LargeSearchBar from './components/SearchForm/LargeSearchBar';
import SmallSearchBar from './components/SearchForm/SmallSearchBar';
import SearchQueue from './components/SearchResults/SearchQueue';
// @ts-ignore : No types exist for this Package
import { NotifyError } from '@/helpers/notification-utils';
import Loader from './components/Loader/Loader';
import NoResultFound from './components/SearchResults/NoResultFound';
import { getAddressFromQueues } from './helpers';
import { AddressObject, IQueue, IQueueOrder, Organization, OrganizationUser, } from './utils/types';
import useAuth from '@/modules/auth/hooks';
import { trackEvents } from '../../../helpers/utilities';

type ReduxStateProps = ConnectedProps<typeof connector>;

type ReduxUserProps = ReduxStateProps;

type OrderQueueModalProps = ReduxUserProps & {
  onClose: () => void;
};

const POLLING_EVERY_MS = 30000;

const OrderQueueModal = ({
  onClose,
  organization,
  allUsers,
}: OrderQueueModalProps) => {
  const { user } = useAuth();
  const [data, setData] = useState<any>([]);
  const [isQueueRequested, setQueueRequested] = useState<boolean>(false);
  const [showLargeSearchBar, setShowLargeSearchBar] = useState<boolean>(false);
  /** Searched Address or OrderId when user clicked on suggestion or hit enter */
  const [searchedAddress, setSearchedAddress] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearchOrderEmpty, setSearchOrderEmpty] = useState<boolean>(false);
  let showNetworkError = true;

  const selectedOrganization = organization?.filter(
    (org: any) => org.id === user.organization_id
  )[0];

  const setDataFromApi = async () => {
    try {
      let { isQueueRequested, data } = await getQueueOrder();

      if(data) {
        trackEvents('order-queue__filter_orders')
      }

      const sortData = (data: IQueue[]): IQueue[] => {
        return data.map((queue: IQueue) => {
          return {
            ...queue,
            orders: queue.orders.sort((a: any, b: any) => {
              return a.estimatedDeliveryTime.localeCompare(
                b.estimatedDeliveryTime
              );
            }),
          };
        });
      };
      const sortedData = sortData(data);
      setData(sortedData);
      setQueueRequested(isQueueRequested);
    } catch (error: any) {
      /** Error notification shows only on Initial Api call */
      if (showNetworkError) {
        NotifyError(
          error?.message ||
            'There was some error while fetching Queue Data. Please try again in Some time!'
        );
      }
      console.error('Error While Fetching Data: ', data);
    }
  };

  const getInitialData = async () => {
    setIsLoading(true);
    setDataFromApi().then(() => {
      setIsLoading(false);
      showNetworkError = false;
    });
  };

  useEffect(() => {
    trackEvents('order-queue__visible')
    
    // Initial API Call
    getInitialData();

    // Polling Function for Order Queues Data
    const interval = setInterval(async () => {
      await setDataFromApi();
    }, POLLING_EVERY_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const addresses: AddressObject[] = useMemo(
    () => getAddressFromQueues(data),
    [data]
  );

  useEffect(() => {
    getFilterOrders(data);
  }, [searchedAddress]);

  const getFilterOrders = (queues: IQueue[]) => {
    return queues.map((queue: IQueue) => {
      return {
        ...queue,
        searchOrders: queue.orders.filter((order: IQueueOrder) => {
          if (
            order.address
              .toLowerCase()
              .includes(searchedAddress.toString().toLowerCase())
          ) {
            return true;
          } else {
            return order.orderId.toString().includes(searchedAddress);
          }
        }),
      };
    });
  };
  const filterQueue = useMemo(() => getFilterOrders(data), [searchedAddress]);

  useEffect(() => {
    const getSearchOrders = filterQueue
      .map((queue) => queue.searchOrders)
      .flat();
    if (getSearchOrders.length === 0) {
      setSearchOrderEmpty(true);
    } else if (getSearchOrders.length > 0) {
      setSearchOrderEmpty(false);
    }
  }, [searchedAddress]);

  return (
    <div className='order-queue-modal-container'>
      <div className='order-queue-modal-header'>
        {!showLargeSearchBar ? (
          <>
            <div className='title-bar'>
              <h2>{selectedOrganization?.title || Strings.ModalTitle}</h2>
              <h4>{Strings.ModaleSubtitle}</h4>
            </div>
            <div className='order-queue-modal-header-section'>
              <div
                className='search-bar'
                onClick={() => setShowLargeSearchBar(true)}
              >
                <SmallSearchBar
                  placeholder={Strings.SearchPlaceholder}
                  prefix={<SearchIcon />}
                />
              </div>
              <div className='close-button' onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
          </>
        ) : (
          <>
            <LargeSearchBar
              values={addresses}
              searchTerm={searchTerm}
              setSearchTerm={(value) => setSearchTerm(value)}
              setSearchedAddress={(data) => setSearchedAddress(data)}
              resetSearchedAddress={() => setSearchedAddress('')}
              showSearchBar={showLargeSearchBar}
              onClose={() => {
                setShowLargeSearchBar(false);
                setSearchTerm('');
              }}
            />
          </>
        )}
      </div>
      <div className='order-queue-modal-main-content'>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {/* Normal Mode */}
            {searchedAddress === '' && data && (
              <>
                {data.map((queue: IQueue) => {
                  return (
                    <SingleQueue
                      queue={queue}
                      key={queue.id}
                      users={allUsers}
                    />
                  );
                })}
                <AddNewQueue isQueueRequested={isQueueRequested} />
              </>
            )}
            {/* Search Result Mode */}
            {searchedAddress !== '' && filterQueue && !isSearchOrderEmpty && (
              <>
                {filterQueue.map((queue) => {
                  return (
                    <div key={queue.id}>
                      {queue.searchOrders.length !== 0 && (
                        <SearchQueue
                          queue={queue}
                          key={queue.id}
                          users={allUsers}
                        />
                      )}
                    </div>
                  );
                })}
                <AddNewQueue isQueueRequested={isQueueRequested} />
              </>
            )}
            {/* No results found mode */}
            {searchedAddress !== '' && isSearchOrderEmpty && (
              <>
                <NoResultFound />
              </>
            )}
          </>
        )}
      </div>
      <div className='order-queue-modal-footer'>
        <p>{Strings.FooterText1}</p>
        <p>
          Contact us at{' '}
          <a href='mailto:support@siterecon.ai?subject=Order prioritization request'>
            <span
              style={{
                color: '#0099FF',
                cursor: 'pointer',
                fontWeight: '500',
                textDecoration: 'underline',
              }}
            >
              support@siterecon.ai
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  organization: state.teamManagement.organisationData as Organization[],
  allUsers: state.teamManagement.organisationUserData as OrganizationUser[],
});

const connector = connect(mapStateToProps);
export default connector(OrderQueueModal);
